export const state = () => ({
    currentProduct: null,
});

export const mutations = {
    setCurrentProduct(state, currentProduct) {
        state.currentProduct = currentProduct;
    },
};

export const actions = {
    updateProduct(context, payload) {
        context.commit("setCurrentProduct", payload);
    },
    removeProduct(context) {
        context.commit("setCurrentProduct", null);
    },
};

export const getters = {
    getCurrentProduct(state) {
        return state.currentProduct;
    },
};
