import { parse } from "cookie";

export const actions = {
    nuxtServerInit({ commit, dispatch }, { req }) {
        let serverCookies = parse(req.headers.cookie || "") || {};
        commit("handbook/setLightMode", !!serverCookies["light-mode"]);

        dispatch("i18nPreference/fetchDismissedLocales", req);
        dispatch("i18nPreference/fetchSuggestedLocale", req);
    },
};
