<template>
    <ProInputGroup v-bind="$attrs" v-slot="{ id, attrs, hasErrors }" :class="{ 'input-group--square': square }">
        <div class="pro-input">
            <div v-if="$slots.before" class="pro-input__before">
                <slot name="before" />
            </div>
            <div class="pro-input__container">
                <input
                    :id="id"
                    ref="input"
                    :value="value"
                    @input="$emit('input', $event.target.value)"
                    v-bind="{ type: 'text', autocomplete: 'off', ...attrs }"
                    :aria-describedby="hasErrors ? `${id}-error` : null"
                />
            </div>
            <div v-if="$slots.after" class="pro-input__after">
                <slot name="after" />
            </div>
        </div>
    </ProInputGroup>
</template>

<script>
import ProInputGroup from "./ProInputGroup";

export default {
    components: { ProInputGroup },
    inheritAttrs: false,
    props: {
        value: String,
        square: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        blur() {
            this.$refs.input.blur();
        },
        select() {
            this.$refs.input.select();
        },
        setSelectionRange(start, end) {
            this.$refs.input.setSelectionRange(start, end);
        },
        clear() {
            this.$refs.input.value = "";
            this.$emit("input", "");
        },
    },
};
</script>
