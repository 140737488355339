import axios from "axios";

// Inject context into the request function so that it can access store etc.
export default (context, inject) => {
    const api = axios.create({
        baseURL: process.env.ADMIN_API_URL,
        timeout: process.server ? 5000 : 0, // 5 seconds for server, disabled client side
    });

    // Set Accept-Language header on all requests
    api.interceptors.request.use(function (config) {
        config.headers["Accept-Language"] = context.app.i18n.locales.find((l) => l.code == context.app.i18n.locale).iso;
        return config;
    });

    inject("adminApi", api);
};
