export const state = () => ({
    offset: 0,
    height: 0,
    count: 0,
    order: 0,
});

export const mutations = {
    setOffset(state, offset) {
        state.offset = offset;
    },
    setHeight(state, height) {
        state.height = height;
    },
    incrementCount(state) {
        state.count = state.count + 1;
    },
    decrementCount(state) {
        state.count = state.count - 1;
    },
    setOrder(state, order) {
        state.order = order;
    },
};

export const actions = {
    openDropdown(context, { order, height, offset }) {
        context.commit("setOrder", order);
        context.commit("incrementCount");
    },
    closeDropdown(context) {
        context.commit("decrementCount");
    },
};

export const getters = {
    getHeight(state) {
        return state.height;
    },
    getOffset(state) {
        return state.offset;
    },
    getOrder(state) {
        return state.order;
    },
    getIsActive(state) {
        return state.count > 0;
    },
};
