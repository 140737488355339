<template>
    <div v-bind="$attrs" class="page-content" :class="{ 'page-content--behind-nav': behindNav }">
        <main role="main" :aria-label="$t('a11y-labels.main.content')">
            <slot />
        </main>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from "@/components/navigation/SiteFooter";

import ScrollWatch from "@/mixins/scrollWatch";

export default {
    name: "Page",

    components: { SiteFooter },
    mixins: [ScrollWatch],
    props: {
        behindNav: Boolean,
    },
};
</script>
