import cookie from "cookie"; // Server side cookies
import cookies from "js-cookie"; // Client side cookies
import parser from "accept-language-parser";
import { locales } from "../i18n.config";

export const state = () => ({
    suggestedLocale: null,
    dismissedLocales: [],
    shouldShowLocaleBanner: true,
});

export const mutations = {
    setSuggestedLocale(state, locale) {
        state.suggestedLocale = locale;
    },
    setDismissedLocales(state, locales) {
        state.dismissedLocales = locales;
    },
    setShouldShowLocaleBanner(state, shouldShowLocaleBanner) {
        state.shouldShowLocaleBanner = shouldShowLocaleBanner;
    },
};

export const actions = {
    // Called from `./index.js`
    fetchSuggestedLocale(context, req) {
        const cookies = cookie.parse(req.headers["cookie"] ?? "") ?? {};
        const preferredLocale = cookies["preferred-locale"];
        const cloudfrontViewerCountry = req.headers["cloudfront-viewer-country"];
        const acceptLanguage = req.headers["accept-language"];

        let suggestedLocale = null;

        if (preferredLocale) {
            const locale = locales.find((l) => l.code && l.code === preferredLocale);
            if (locale) suggestedLocale = locale;
        }

        if (cloudfrontViewerCountry && !suggestedLocale) {
            const locale = locales.find((l) => l.countries && l.countries.includes(cloudfrontViewerCountry.toUpperCase()));
            if (locale) suggestedLocale = locale;
        }

        if (acceptLanguage && !suggestedLocale) {
            let locale = parser.pick(
                locales.map((l) => l.iso),
                acceptLanguage,
                { loose: true }
            );
            if (locale) locale = locales.find((l) => l.iso === locale);
            if (locale) suggestedLocale = locale;
        }

        if (suggestedLocale) {
            context.commit("setSuggestedLocale", suggestedLocale);
        }
    },

    // Called from `./index.js`
    fetchDismissedLocales(context, req) {
        const cookies = cookie.parse(req.headers["cookie"] ?? "") ?? {};
        const dismissedLocales = cookies["dismissed-locales"];
        if (dismissedLocales && dismissedLocales.length) context.commit("setDismissedLocales", dismissedLocales.split(","));
    },

    // Called client side only
    savePreferredLocale(context, locale) {
        cookies.set("preferred-locale", locale);
        locale = locales.find((l) => l.code && l.code === locale);
        context.commit("setSuggestedLocale", locale ?? null);
    },

    // Called client side only
    dismissLocale(context, locale) {
        let locales = [...context.state.dismissedLocales, locale];
        locales = [...new Set(locales)]; // Remove duplicate locales
        context.commit("setDismissedLocales", locales);
        cookies.set("dismissed-locales", locales.join(","));
    },
};

export const getters = {
    getSuggestedLocale: (state) => state.suggestedLocale,
    getDismissedLocales: (state) => state.dismissedLocales,
    getShouldShowLocaleBanner: (state) => state.shouldShowLocaleBanner,
};
