export default {
    data: () => {
        return {
            scrollElement: null,
            scrollCompletion: 0,
        };
    },

    methods: {
        calculateScrollAmount() {
            const rect = this.scrollElement.getBoundingClientRect();

            const scrollHeight = rect.height - document.documentElement.clientHeight;
            const scrollY = Math.min(Math.max(rect.y * -1, 0), scrollHeight);
            const scrollCompletion = scrollY / scrollHeight;

            if (scrollCompletion > this.scrollCompletion) this.scrollCompletion = scrollCompletion;
        },
        getRoundedPercentage() {
            const factor = 10;
            return (Math.floor(this.scrollCompletion * factor) * 100) / factor;
        },
        recordEvent() {
            fathom.trackEvent(`scroll-percent-${this.getRoundedPercentage()}`);
        },
    },

    mounted() {
        const element = document.querySelector("main"); // seems the most reasonable unit of measurment
        if (element) {
            this.scrollElement = element;

            new ResizeObserver(() => {
                this.calculateScrollAmount();
            }).observe(element);

            window.addEventListener("scroll", this.calculateScrollAmount, { passive: true });
            window.addEventListener("beforeunload", this.recordEvent);
        }
    },

    beforeDestroy() {
        if (this.scrollElement) {
            this.recordEvent();
            window.removeEventListener("scroll", this.calculateScrollAmount, { passive: true });
            window.removeEventListener("beforeunload", this.recordEvent);
        }
    },
};
