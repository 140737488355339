<template>
    <div class="horizontal-scroll-container">
        <div v-if="!hideLeftBtn" class="horizontal-scroll-container__scroll-button left" :style="{ opacity: canScrollLeft == true ? 1 : 0 }" aria-hidden="true">
            <slot name="scrollLeft">
                <button class="default-scroller" type="button" @click="scrollLeft()" :tabindex="canScrollLeft ? 0 : -1">
                    <ProIcon icon="cta-right" :style="{ transform: 'rotate(180deg)' }" />
                </button>
            </slot>
        </div>
        <div class="horizontal-scroll-container__content" :class="{ 'full-left': !canScrollLeft, 'full-right': !canScrollRight }" ref="content">
            <div ref="leftWatcher" class="horizontal-scroll-container__watcher"></div>
            <slot />
            <div ref="rightWatcher" class="horizontal-scroll-container__watcher"></div>
        </div>

        <div
            v-if="!hideRightBtn"
            class="horizontal-scroll-container__scroll-button right"
            :style="{ opacity: canScrollRight == true ? 1 : 0 }"
            aria-hidden="true"
        >
            <slot name="scrollRight">
                <button class="default-scroller" type="button" @click="scrollRight()" :tabindex="canScrollRight ? 0 : -1">
                    <ProIcon icon="cta-right" />
                </button>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        scrollAmount: {
            type: Number,
            default: 100,
        },
        hideLeftBtn: {
            type: Boolean,
            default: false,
        },
        hideRightBtn: {
            type: Boolean,
            default: false,
        },
    },

    data: () => {
        return {
            canScrollLeft: false,
            canScrollRight: false,
            observerOptions: {
                root: null,
                rootMargin: "0px",
                threshold: [0, 1],
            },
        };
    },

    methods: {
        scrollLeft() {
            this.handleScroll(this.scrollAmount * -1);
        },
        scrollRight() {
            this.handleScroll(this.scrollAmount);
        },
        handleScroll(amount) {
            this.$refs.content.scrollBy({
                top: 0,
                left: amount,
                behavior: "smooth",
            });
        },

        setupLeftObserver() {
            const observer = new IntersectionObserver(([entry] = entries) => {
                this.canScrollLeft = entry.intersectionRatio < 1;
            }, this.observerOptions);
            observer.observe(this.$refs.leftWatcher);
        },

        setupRightObserver() {
            const observer = new IntersectionObserver(([entry] = entries) => {
                this.canScrollRight = entry.intersectionRatio < 0.0000001;
            }, this.observerOptions);
            observer.observe(this.$refs.rightWatcher);
        },
    },

    mounted() {
        this.setupLeftObserver();
        this.setupRightObserver();
    },
};
</script>
