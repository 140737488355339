import cookies from "js-cookie";
import { deepClone } from "../utils";

export const state = () => ({
    userFetched: false,
    user: null,
    loginModalOpen: false,
    registerModalOpen: false,
});

export const mutations = {
    setUser(state, user) {
        state.user = user;
    },

    setUserFetched(state, userFetched) {
        state.userFetched = userFetched;
    },

    setLoginModalOpen(state, open) {
        state.loginModalOpen = open;
    },

    setRegisterModalOpen(state, open) {
        state.registerModalOpen = open;
    },

    setUserActive(state) {
        state.user.active = 1;
    },

    setUserToBeta(state, betaUser) {
        if (!state.user.betaUsers) {
            state.user.betaUsers = [];
        }

        state.user.betaUsers.push(betaUser);
    },
};

export const actions = {
    async fetchUser(context) {
        if (cookies.get("user") && cookies.get("token")) {
            try {
                let { data } = await this.$folioApiRequest(
                    "userLoad",
                    {
                        userId: cookies.get("user"),
                        user: cookies.get("user"),
                        token: cookies.get("token"),
                    },
                    { authenticate: false }
                );

                if (data.success) {
                    context.dispatch("persistUser", data.user);
                } else {
                    throw "Invalid user ID or token";
                }
            } catch (error) {
                console.log("Failed to fetch user: " + error);
            }
        }

        context.commit("setUserFetched", true);
    },

    async attemptLogin(context, payload) {
        try {
            let { data } = await this.$folioApiRequest(
                "userLogin",
                {
                    username: payload.username,
                    password: payload.password,
                },
                { authenticate: false }
            );

            if (data.success) {
                context.dispatch("persistUser", data.user);
            } else {
                console.error("Failed to login: " + (data.errors.message ? data.errors.message : data.errors.empty));
                throw new Error(data.errors.message ? data.errors.message : data.errors.empty);
            }
        } catch (error) {
            console.error("Failed to login: " + error);
            throw error;
        }
    },

    async resendConfirmationEmail(context, payload) {
        try {
            let { data } = await this.$folioApiRequest("publicBetaResendConfirmationEmail", { lang: payload.lang });

            if (!data.success) {
                throw new Error("Failed to send.");
            }
        } catch (error) {
            console.log("Failed to send email: " + error);
            throw error;
        }
    },

    async reapplyToBeta(context, payload) {
        try {
            let { data } = await this.$folioApiRequest("publicBetaReapply", { lang: payload.lang, beta_type_id: payload.beta_type_id });

            if (!data.success) {
                throw new Error("Failed to reapply.");
            } else {
                let user = deepClone(context.state.user);

                if (user.betaUsers) {
                    for (let i = 0; i < user.betaUsers.length; i++) {
                        if (user.betaUsers[i].beta_type_id === payload.beta_type_id) {
                            user.betaUsers[i].selected = "0";
                        }
                    }
                }

                context.commit("setUser", user);
            }
        } catch (error) {
            console.log("Failed to reapply to beta: " + error);
            throw error;
        }
    },

    persistUser(context, user) {
        const options = {
            expires: 365,
            path: "/",
            domain: this.$isDev() ? null : ".procreate.com",
            secure: window.location.protocol === "https:" ? true : false,
            sameSite: "Strict",
        };

        // Persist user login
        cookies.set("user", user.id, options);
        cookies.set("token", user.token, options);

        context.commit("setUser", user);
    },

    addBetaUser(context, betaUser) {
        context.commit("setUserToBeta", betaUser);
    },

    logout(context) {
        const options = {
            path: "/",
            domain: this.$isDev() ? null : ".procreate.com",
        };

        cookies.remove("user", options);
        cookies.remove("token", options);

        context.commit("setUser", null);
    },

    openLoginModal(context) {
        context.commit("setLoginModalOpen", true);
    },

    closeLoginModal(context) {
        context.commit("setLoginModalOpen", false);
    },

    openRegisterModal(context) {
        context.commit("setRegisterModalOpen", true);
    },

    closeRegisterModal(context) {
        context.commit("setRegisterModalOpen", false);
    },
};

export const getters = {
    getUserFetched(state) {
        return state.userFetched;
    },

    getUser(state) {
        return state.user;
    },

    isLoggedIn(state) {
        return !!state.user;
    },

    isLoginModalOpen(state) {
        return state.loginModalOpen;
    },

    isRegisterModalOpen(state) {
        return state.registerModalOpen;
    },
};
