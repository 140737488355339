<template>
    <section class="modals-container">
        <transition name="modal-bg-fade" appear>
            <div v-show="hasActiveModals" class="modals-container__background">
                <div class="modals-container__background-content"></div>
            </div>
        </transition>

        <ClientOnly>
            <PortalTarget name="modals" multiple @change="handleModalPortalUpdate" />
        </ClientOnly>
    </section>
</template>

<script>
export default {
    data: () => ({
        hasActiveModals: false,
    }),

    methods: {
        handleModalPortalUpdate(hasActiveModals) {
            this.$emit("active", hasActiveModals);
            this.hasActiveModals = hasActiveModals;
            this.$nextTick(() => (document.body.style.overflow = hasActiveModals ? "hidden" : null));
        },
    },
};
</script>
