import Vue from "vue";

Vue.directive("focus-method", {
    inserted(element, binding) {
        window.addEventListener(
            "mousedown",
            () => {
                "touch" !== element.getAttribute("data-focus-method") && element.setAttribute("data-focus-method", "mouse");
            },
            true
        );

        window.addEventListener(
            "keydown",
            () => {
                element.setAttribute("data-focus-method", "key");
            },
            true
        );

        window.addEventListener(
            "touchstart",
            () => {
                element.setAttribute("data-focus-method", "touch");
            },
            true
        );
    },
});
