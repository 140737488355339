<template>
    <ul class="navigation-links" v-if="items" v-bind="$attrs">
        <li class="navigation-link" v-for="(item, i) in items" :key="i">
            <ProLink
                :to="{ name: item?.name ?? null }"
                :href="item?.href ?? null"
                :aria-label="handleLinkLabel(item)"
                :target="item?.href ? '_blank' : '_self'"
            >
                <span
                    aria-hidden="true"
                    class="navigation-link__title"
                    :data-position-count="item.name == 'careers' && positionCount > 0 ? positionCount : null"
                    >{{ handleLinkTitle(item) }}
                    <ProIcon v-if="iconIfExternal && item?.external" icon="external" />
                </span>
                <span aria-hidden="true" v-if="details" class="navigation-link__details">{{ item.description }}</span>
            </ProLink>
        </li>
    </ul>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    props: {
        items: {
            type: Array,
            default: [],
        },
        useProductName: {
            type: Boolean,
            default: false,
        },
        details: {
            type: Boolean,
            default: false,
        },
        iconIfExternal: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            positionCount: "employment/getPositionCount",
        }),
    },

    methods: {
        handleLinkTitle(item) {
            if (item.main_link && this.useProductName == true) {
                return item.product_title;
            }
            return item.title;
        },

        handleLinkLabel(item) {
            const title = this.handleLinkTitle(item);
            let label = title;
            if (this.details && item?.description) {
                label = `${label} - ${item.description}`;
            }
            return label;
        },
    },
};
</script>
