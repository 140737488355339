<template>
    <div class="input-group" :class="{ 'input-group--has-errors': hasErrors }">
        <div class="input-group__label" :class="{ 'sr-only': labelHidden }">
            <label :for="labelId ?? id" v-html="label"></label>
            <button v-if="help" ref="help" type="button" class="input-group__help" title="Help">
                <ProIcon icon="info" />
            </button>
        </div>
        <slot
            v-bind:id="id"
            v-bind:attrs="$attrs"
            v-bind:hasErrors="hasErrors"
            v-bind:aria-invalid="hasErrors"
            v-bind:aria-errormessage="`${labelId ?? id}-error`"
        />
        <p v-if="hasErrors" :id="`${labelId ?? id}-error`" class="input-group__error">
            <small class="sr-only">Validation error: </small>
            {{ errors[0] }}
        </p>
    </div>
</template>

<script>
import { uid } from "~/utils";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
    inheritAttrs: false,
    props: {
        labelId: String,
        label: {
            type: String,
            required: true,
        },
        labelHidden: Boolean,
        help: String,
        errors: Array,
    },
    data: () => ({
        id: uid(),
    }),
    methods: {
        initOrDestoryTippy() {
            if (this.help) {
                if (!this.$refs.help._tippy) {
                    tippy(this.$refs.help, { content: this.help, allowHTML: true });
                }
            } else if (this.$refs.help && this.$refs.help._tippy) {
                this.$refs.help._tippy.destroy();
            }
        },
    },
    computed: {
        hasErrors() {
            return this.errors && this.errors.length;
        },
    },
    watch: {
        help() {
            this.initOrDestoryTippy();
        },
    },
    mounted() {
        // Refresh UID on mounted, solves an issue with hydration
        this.id = uid();

        this.initOrDestoryTippy();
    },
};
</script>
