import { parseApiDate } from "~/utils";
import cookies from "js-cookie";

export const state = () => ({
    procreate: null,
    pocket: null,
    dreams: null,
});

export const mutations = {
    setProcreate(state, procreate) {
        state.procreate = procreate;
    },

    setPocket(state, pocket) {
        state.pocket = pocket;
    },

    setDreams(state, dreams) {
        state.dreams = dreams;
    },
};

export const actions = {
    async fetchPrices(context) {
        if (!context.getters.getProcreate) {
            try {
                const { data } = await this.$adminApi.get(`/app-store/?only=formattedPrice,currency`);
                context.commit("setProcreate", data.products.procreate);
                context.commit("setPocket", data.products.pocket);
                context.commit("setDreams", data.products.dreams);
            } catch (e) {
                console.log("Failed to fetch prices: " + error);
            }
        }
    },
};

export const getters = {
    getProcreate(state) {
        return state.procreate;
    },

    getPocket(state) {
        return state.pocket;
    },

    getDreams(state) {
        return state.dreams;
    },
};
