import Vue from "vue";

class Fade {
    constructor() {
        // Exit early if IntersectionObserver is not supported
        if (!process.client || !("IntersectionObserver" in window)) return;

        this.items = new Map();
        this.observer = new IntersectionObserver((entries) => this.update(entries), { rootMargin: "100% 0px -20% 0px", threshold: [0, 0.5, 1] });
    }

    add(element, binding) {
        if (!this.observer) return;

        this.items.set(element, {
            element: element,
            mods: binding.modifiers,
            value: binding.value,
        });

        element.classList.add("fade");

        if (binding.modifiers.delayed == true) {
            element.classList.add("fade--delayed");
        }

        this.observer.observe(element);
    }

    remove(element) {
        if (!this.observer) return;

        element.classList.remove("fade", "fade--in");
        this.observer.unobserve(element);
        this.items.delete(element);
    }

    update(entries) {
        window.requestAnimationFrame(() => {
            for (const entry of entries) {
                const item = this.items.get(entry.target);

                if (entry.intersectionRatio > 0 || entry.boundingClientRect.y <= 0) {
                    entry.target.classList.remove("fade--in");
                } else {
                    entry.target.classList.add("fade--in");
                }
            }
        });
    }
}

const fade = new Fade();

Vue.directive("fade", {
    inserted(element, binding) {
        if (binding?.value === false) return;
        fade.add(element, binding);
    },
    unbind(element) {
        fade.remove(element);
    },
});
