import Vue from "vue";
import { throttle } from "~/utils";

class Parallax {
    constructor() {
        // Exit early if IntersectionObserver is not supported
        if (!process.client || !("IntersectionObserver" in window)) return;

        this.items = new Map();
        this.observer = new IntersectionObserver((entries) => this.update(entries), { rootMargin: "50% 0px 50% 0px" });

        window.addEventListener(
            "scroll",
            throttle(() => this.move(), 10),
            { passive: true }
        );
        window.addEventListener(
            "resize",
            throttle(() => this.move(), 100),
            { passive: true }
        );

        // Temporary workaround for chrome's scroll jitter bug
        window.addEventListener("mousewheel", function () {});
    }

    add(element, binding) {
        if (!this.observer) return;

        const parent = element.parentElement;

        this.items.set(element, {
            element: element,
            parent: parent,
            mods: binding.modifiers,
            value: binding.value,
            active: false,
        });

        this.observer.observe(parent);

        element.style["backface-visibility"] = "hidden";
        element.style["transition"] = "transform 0.1s ease";
    }

    remove(element) {
        if (!this.observer) return;

        this.observer.unobserve(element.parentElement);
        this.items.delete(element);
    }

    update(entries) {
        for (const entry of entries) {
            const children = Array.from(this.items.values()).filter((item) => item.parent == entry.target);
            children.forEach((item) => (item.active = entry.isIntersecting));
        }

        throttle(() => this.move(), 10);
    }

    move() {
        const timeStarted = Date.now();
        const scrollTop = window.scrollY || window.pageYOffset;
        const activeItems = Array.from(this.items.values()).filter((item) => item.active);
        const windowHeight = window.innerHeight;

        this.lastTimeStarted = timeStarted;

        window.requestAnimationFrame(() => {
            for (let item of activeItems) {
                if (timeStarted !== this.lastTimeStarted) return; // Cancel this function if it has been called again subsequently

                const element = item.element;

                if (element.offsetParent !== null) {
                    // Returns null if element is hidden
                    const elementHeight = element.clientHeight || element.scrollHeight;
                    const elementOffsetTop = element.offsetTop + element.offsetParent.offsetTop;
                    const offset = elementOffsetTop * -1 * item.value;
                    const innerHeight = item.mods.to || item.mods.to ? windowHeight * 1.5 : windowHeight;
                    const position = (scrollTop + innerHeight - elementHeight / 2 - innerHeight / 2) * item.value + offset;

                    if ((item.mods.to && position <= 0) || (item.mods.from && position >= 0) || (!item.mods.to && !item.mods.from)) {
                        element.style["transform"] = `translate3d(0px, ${position.toFixed(2) * -1}px, 0px)`;
                    } else {
                        element.style["transform"] = `translate3d(0px, 0px, 0px)`;
                    }
                }
            }
        });
    }
}

const parallax = new Parallax();

Vue.directive("parallax", {
    inserted(element, binding) {
        parallax.add(element, binding);
    },
    unbind(element) {
        parallax.remove(element);
    },
});
