import Vue from "vue";
import "wicg-inert";

class TrapManager {
    constructor() {
        this.traps = new Map();
    }

    trap(element) {
        let undoTrappings = [];
        let activeEl = document.activeElement;

        undoTrappings.push(() => activeEl && setTimeout(() => activeEl.focus()));

        crawlSiblingsUp(element, (sibling) => {
            let cache = sibling.hasAttribute("inert");

            sibling.setAttribute("inert", "inert");

            undoTrappings.push(() => cache || sibling.removeAttribute("inert"));
        });

        if (!element.contains(activeEl)) {
            focusFirstElement(element);
        }

        this.traps.set(element, undoTrappings);
    }

    untrap(element) {
        let undoTrappings = this.traps.get(element);

        if (undoTrappings) {
            while (undoTrappings.length) undoTrappings.pop()();
        }
    }
}

function crawlSiblingsUp(el, callback) {
    if (el.isSameNode(document.body) || !el.parentNode) return;

    Array.from(el.parentNode.children).forEach((sibling) => {
        if (!sibling.isSameNode(el)) callback(sibling);

        crawlSiblingsUp(el.parentNode, callback);
    });
}

function focusFirstElement(el) {
    let autofocus = el.querySelector("[autofocus]");

    if (autofocus) {
        setTimeout(() => autofocus.focus());
    } else {
        // All focusable element types...
        let selector = "a, button, input, textarea, select, details, [tabindex]:not([tabindex='-1'])";

        let firstFocusable = Array.from(el.querySelectorAll(selector))
            // All non-disabled elements...
            .filter((el) => !el.hasAttribute("disabled"))[0];

        setTimeout(() => firstFocusable && firstFocusable.focus());
    }
}

const traps = new TrapManager();

Vue.directive("trap", {
    inserted(element, binding) {
        if (binding.value) traps.trap(element);
    },

    update(element, binding) {
        if (binding.value && !binding.oldValue) traps.trap(element);
        else if (!binding.value && binding.oldValue) traps.untrap(element);
    },

    unbind(element) {
        traps.untrap(element);
    },
});
