module.exports = {
    locales: [
        { code: "en", iso: "en-US", file: "en-US", name: "English (US)" },
        {
            code: "cn",
            iso: "zh-CN",
            file: "zh-CN",
            countries: ["CN", "MO", "TW", "HK"], // ISO_3166-1_alpha-2 country codes
            name: "简体中文",
        },
        {
            code: "jp",
            iso: "ja",
            file: "ja",
            countries: ["JP"], // ISO_3166-1_alpha-2 country codes
            name: "日本語",
        },
    ],
    defaultIso: "en-US", //custom key for middleware/locale-detection.
    defaultLocale: "en",
    baseUrl: "https://procreate.com",
    strategy: "prefix_and_default",
    lazy: true,
    vuex: false,
    vueI18nLoader: true,
    langDir: "lang/",
    vueI18n: {
        fallbackLocale: "en",
        silentTranslationWarn: true,
        silentFallbackWarn: true, //annoying if not
    },
    detectBrowserLanguage: false,
};
