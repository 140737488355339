export const state = () => ({
    articles: [],
    homeArticles: null,
    article: null,
    relatedArticles: [],
    categories: [],
    currentCategory: null,
    nextCursor: null,
});

export const mutations = {
    setArticles(state, articles) {
        state.articles = articles;
    },

    setHomeArticles(state, homeArticles) {
        state.homeArticles = homeArticles;
    },

    setArticle(state, article) {
        state.article = article;
    },

    setCategories(state, categories) {
        state.categories = categories;
    },

    setRelatedArticles(state, relatedArticles) {
        state.relatedArticles = relatedArticles;
    },

    setCurrentCategory(state, currentCategoryId) {
        const selectedCategory = state.categories.find((c) => c.id == currentCategoryId);
        if (selectedCategory) {
            state.currentCategory = selectedCategory;
        } else {
            state.currentCategory = null;
        }
    },

    setNextCursor(state, cursor) {
        state.nextCursor = cursor;
    },
};

export const actions = {
    async fetchArticles(context, options) {
        const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
        try {
            const queryParams = new URLSearchParams({ lang: language, ...options });

            const { data } = await this.$adminApi.get(`/insight/articles/?${queryParams.toString()}`);

            context.commit("setNextCursor", data.next_cursor);

            if (options?.cursor) {
                context.commit("setArticles", [...context.getters.getArticles, ...data.data]);
            } else {
                context.commit("setArticles", data.data);
            }
            return data;
        } catch (error) {
            console.log("Failed to fetch insight articles: " + error);
            throw error;
        }
    },

    async fetchHomeArticles(context, options) {
        const language = context.getters.getLanguageForLocaleCode(options?.lang ?? this.$i18n.locale);
        if (!context.getters.getHomeArticles) {
            try {
                let { data } = await this.$adminApi.get(`/insight/articles/?lang=${language}&limit=2`);
                context.commit("setHomeArticles", data.data);
            } catch (error) {
                console.log("Failed to fetch home insight articles: " + error);
            }
        }
    },

    async fetchCategories(context) {
        const language = context.getters.getLanguageForLocaleCode(this.$i18n.locale);
        try {
            const { data } = await this.$adminApi.get(`/insight/categories/?lang=${language}`);
            context.commit("setCategories", data.categories);
            return data;
        } catch {
            console.log("Failed to fetch insight categories: " + error);
            throw error;
        }
    },

    async fetchArticle(context, payload) {
        const language = context.getters.getLanguageForLocaleCode(this.$i18n.locale);
        try {
            let { data } = await this.$adminApi.get(`/insight/article/${payload.year}/${payload.slug}/?lang=${language}`);
            context.commit("setArticle", data.article);
            if (data.related_articles) {
                context.commit("setRelatedArticles", data.related_articles);
            }
        } catch (error) {
            console.log("Failed to fetch insight articles: " + error);
            throw error;
        }
    },
};

export const getters = {
    getArticles(state) {
        return state.articles;
    },

    getHomeArticles(state) {
        return state.homeArticles;
    },

    getArticle(state) {
        return state.article;
    },

    getCategories(state) {
        return state.categories;
    },

    getRelatedArticles(state) {
        return state.relatedArticles;
    },

    getCurrentCategory(state) {
        return state.currentCategory;
    },

    getNextCursor(state) {
        return state.nextCursor;
    },

    /**
     * Maps a locale code to a language code. Useful for
     * some languages in multiple locales.
     */
    getLanguageForLocaleCode() {
        return (code) => {
            switch (code) {
                case "jp":
                    return "ja";
                case "cn":
                    return "zh-CN";
                default:
                    return "en-US";
            }
        };
    },
};
