<template>
    <Portal to="modals" v-if="showModal">
        <div v-trap="shouldTrap" v-bind="$attrs" :key="id" class="modal" role="dialog" aria-modal="true" ref="modal">
            <div class="modal__background" @click="cancel"></div>
            <div class="modal__content-container dark-mode">
                <transition name="modal-content-fade" appear @after-enter="autofocus" @after-leave="final">
                    <div v-show="showContent" :class="modalClass()" class="modal__content" ref="modalContent">
                        <slot />

                        <button type="button" v-if="dimissable && showCloseButton" @click="cancel" class="modal__close" aria-label="Close modal">
                            <ProIcon icon="modal-close" />
                        </button>
                    </div>
                </transition>
            </div>
        </div>
    </Portal>
</template>

<script>
import { uid } from "~/utils";

export default {
    props: {
        id: {
            type: String,
            default: () => `modal-${uid()}`,
        },

        // Allows the modal to be closed by clicking outside the modal window
        dimissable: {
            type: Boolean,
            default: true,
        },

        showCloseButton: {
            type: Boolean,
            default: true,
        },
    },

    data: function () {
        return {
            showContent: false,
            showModal: false,
            shouldTrap: false,
            resolve: null,
        };
    },

    methods: {
        open() {
            this.showContent = true;
            this.showModal = true;
            const promise = new Promise((resolve) => (this.resolve = resolve));
            this.$emit("opening", promise);
            return promise;
        },

        close() {
            this.showContent = false;
            const promise = new Promise((resolve) => (this.resolve = resolve));
            this.$emit("closing", promise);
            return promise;
        },

        cancel() {
            if (!this.dimissable) return;
            const promise = this.close();
            this.$emit("canceled", promise);
            return promise;
        },

        final() {
            this.showModal = false;
            this.shouldTrap = false;
            this.$emit("closed");
            this.resolve();
        },

        isOpen() {
            return this.showContent;
        },

        autofocus() {
            this.resolve();
            this.shouldTrap = true;
        },

        modalClass() {
            // Dirty hack to resolve issue with VuePortal not passing through the class
            return this.$vnode.data.staticClass;
        },
    },
};
</script>
