<script>
const icons = ((ctx) => {
    let keys = ctx.keys();
    let values = keys.map(ctx);
    return keys.reduce((o, k, i) => {
        k = k.substring(2, k.length - 4); // Remove file extension etc
        o[k] = values[i];
        return o;
    }, {});
})(require.context("assets/icons", true, /\.svg$/));

export default {
    name: "ProIcon",

    props: {
        icon: String,
    },

    render(h) {
        if (!icons[this.icon]) {
            throw new Error(`Unknown icon "${this.icon}"`);
        }

        return h(icons[this.icon]({}), {
            class: `pro-icon pro-icon--${this.icon}`,
            attrs: {
                "aria-hidden": true,
            },
        });
    },
};
</script>
