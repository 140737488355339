<template>
    <ProInputGroup v-bind="$attrs" v-slot="{ id, attrs, hasErrors }" :class="{ 'input-group--square': square }">
        <div class="pro-select">
            <select
                class="pro-select__selector"
                :id="id"
                ref="input"
                v-model="selected"
                :aria-describedby="hasErrors ? `${id}-error` : null"
                v-bind="{ ...{ autocomplete: 'off' }, ...attrs }"
            >
                <slot />
            </select>
            <div class="pro-select__icon" aria-hidden="true">
                <ProIcon icon="select-arrow" />
            </div>
        </div>
    </ProInputGroup>
</template>

<script>
import ProInputGroup from "./ProInputGroup";

export default {
    components: { ProInputGroup },
    inheritAttrs: false,
    props: {
        value: null,
        square: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected: this.value,
        };
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        blur() {
            this.$refs.input.blur();
        },
        select() {
            this.$refs.input.select();
        },
        initOrDestoryTippy() {
            if (this.help) {
                if (!this.$refs.help._tippy) {
                    tippy(this.$refs.help, { content: this.help, allowHTML: true });
                }
            } else if (this.$refs.help && this.$refs.help._tippy) {
                this.$refs.help._tippy.destroy();
            }
        },
    },
    watch: {
        value() {
            this.selected = this.value;
        },
        selected(value) {
            this.$emit("input", value);
        },
    },
};
</script>
