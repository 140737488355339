<template>
    <div
        @keydown.esc="handleDrawer(false)"
        class="product-sub-nav"
        v-bind="$attrs"
        ref="productSubNav"
        :class="{ 'product-sub-nav--active': active, 'product-sub-nav--pocket': product == 'pocket' }"
    >
        <button type="button" v-if="drawerOpen" @click="handleDrawer(false)" class="product-sub-nav__escape-net" aria-hidden="true"></button>
        <nav v-if="currentMenu" class="product-sub-nav__content">
            <div class="product-sub-nav__container">
                <ProButton
                    id="product-sub-nav-toggle"
                    class="product-sub-nav__mobile-toggle"
                    @click="handleDrawer(!drawerOpen)"
                    :aria-expanded="drawerOpen ? true : false"
                    aria-controls="product-sub-nav-drawer"
                    >{{ $t(currentMenu.mainLink).product_title }}
                    <ProIcon icon="select-arrow" :style="{ transform: `${!drawerOpen ? 'none' : 'rotate(180deg)'}` }" />
                </ProButton>
                <HorizontalScrollContainer class="product-sub-nav__items-container">
                    <NavItemsList
                        class="product-sub-nav__items"
                        :items="$t(currentMenu.items).filter((item) => item.in_main_nav !== false) ?? null"
                        useProductName
                    />
                </HorizontalScrollContainer>

                <ProLink v-if="currentMenu.buyLink" :href="currentMenu.buyLink" target="_blank" class="product-sub-nav__purchase"
                    >{{ $t("global.buy_now") }}
                </ProLink>
            </div>
            <TransitionHeight>
                <div
                    v-show="drawerOpen"
                    id="product-sub-nav-drawer"
                    role="region"
                    aria-labelledby="product-sub-nav-toggle"
                    class="product-sub-nav__mobile-drawer"
                >
                    <NavItemsList :items="$t(currentMenu.items).filter((item) => item.in_main_nav !== false) ?? null" />
                </div>
            </TransitionHeight>
        </nav>
    </div>
</template>

<script>
import TransitionHeight from "@/components/TransitionHeight";
import HorizontalScrollContainer from "@/components/HorizontalScrollContainer";

import NavItemsList from "@/components/navigation/NavItemsList";

export default {
    components: {
        TransitionHeight,
        HorizontalScrollContainer,
        NavItemsList,
    },

    props: {
        product: {
            type: String,
            default: null,
        },
    },

    data: () => {
        return {
            active: true,
            drawerOpen: false,
            currentMenu: null,
        };
    },

    computed: {
        procreateMenu() {
            return {
                mainLink: "navigation-procreate.main_link",
                items: "navigation-procreate.menu_items",
                buyLink: "https://apps.apple.com/app/apple-store/id425073498?pt=345446&ct=procreate.com&mt=8",
            };
        },
        pocketMenu() {
            return {
                mainLink: "navigation-pocket.main_link",
                items: "navigation-pocket.menu_items",
                buyLink: "https://apps.apple.com/app/apple-store/id916366645?pt=345446&ct=procreate.com&mt=8",
            };
        },
    },

    methods: {
        setCurrentProductMenu() {
            switch (this.product) {
                case "procreate":
                    return this.procreateMenu;
                    break;
                // case "pocket":
                //     if (this.$i18n.locale != "cn") {
                //         return this.pocketMenu;
                //         break;
                //     }
                default:
                    return null;
                    break;
            }
        },

        handleDrawer(bool = false) {
            this.drawerOpen = bool;
        },

        setupResizeObserver() {
            this.observer = new ResizeObserver(([entry] = entries) => {
                const width = entry.contentRect.width;
                if (width >= 600 && this.drawerOpen) {
                    this.handleDrawer(false);
                }
            });

            this.observer.observe(this.$refs.productSubNav);
        },
    },

    mounted() {
        this.setupResizeObserver();
        this.currentMenu = this.setCurrentProductMenu();
    },

    watch: {
        product() {
            this.currentMenu = this.setCurrentProductMenu();
        },
        $route() {
            if (this.drawerOpen) {
                this.handleDrawer(false);
            }
        },
    },
};
</script>
