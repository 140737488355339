<template>
    <div v-bind="$attrs" class="app-badge">
        <div class="app-badge__icon">
            <img :src="icon" alt="" width="42" height="42" />
        </div>
        <div class="app-badge__text">
            <span v-html="productName" class="app-badge__name"></span>
            <span v-if="productDetails" v-html="productDetails" class="app-badge__details"></span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
        productName: {
            type: String,
            required: true,
        },
        productDetails: {
            type: String,
            default: "",
        },
    },
};
</script>
