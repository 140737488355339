<script>
export default {
    name: "ProLink",
    props: {
        to: Object,
        href: String,
    },
    computed: {
        element() {
            return this.href ? "a" : "nuxt-link";
        },
    },
    render(h) {
        return h(
            this.element,
            {
                class: "pro-link",
                attrs: this.element == "a" ? { href: this.href } : {},
                props: this.element == "nuxt-link" ? { to: this.localePath(this.to) } : {},
            },
            this.$slots.default
        );
    },
};
</script>
