<template>
    <Modal ref="modal" @closed="reset" class="login-modal">
        <form @submit.prevent="login" class="login-modal__form">
            <h2 class="login-modal__heading text-heading text-heading--xs" v-html="$t('auth.log_in_heading')"></h2>

            <div class="login-modal__fields">
                <div class="login-modal__error-message" v-show="errorMessage" id="incorrect-login" :aria-hidden="!errorMessage">
                    {{ errorMessage }}
                </div>

                <ProInput
                    id="username"
                    name="username"
                    autocomplete="username"
                    :placeholder="$t('auth.username_placeholder')"
                    :label="$t('auth.username_placeholder')"
                    v-model="username"
                    required
                    autofocus
                    :aria-invalid="errorMessage"
                    aria-errormessage="incorrect-login"
                    labelHidden
                />

                <ProInput
                    id="password"
                    name="password"
                    autocomplete="current-password"
                    :placeholder="$t('auth.password_placeholder')"
                    :label="$t('auth.password_placeholder')"
                    v-model="password"
                    type="password"
                    required
                    labelHidden
                    :aria-invalid="errorMessage"
                    aria-errormessage="incorrect-login"
                    ref="passwordinput"
                />
            </div>

            <div class="login-modal__form-actions">
                <ProButton v-fathom-event:click="['EKRPSK5R']" class="login-btn" pill blue variant="primary" type="submit" :loading="submitting">{{
                    $t("auth.log_in_cta")
                }}</ProButton>
                <ProButton target="_blank" variant="muted" href="https://folio.procreate.com/forgot">{{ $t("auth.forgot_password_cta") }}</ProButton>
            </div>

            <div class="login-modal__footer">
                <p class="text-body">
                    <span v-html="$t('global.dont_have_procreate_id')"></span>
                    <button type="button" @click="register">{{ $t("global.sign_up_now") }}</button>
                </p>
            </div>
        </form>

        <div class="login-modal__cta">
            <h3 class="text-heading text-heading--xs" v-html="$t('global.dont_have_procreate_id')"></h3>
            <div>
                <ProButton variant="alt" pill @click="register">{{ $t("global.sign_up_now") }}</ProButton>
            </div>
            <div class="bg" :style="{ backgroundImage: `url('${$asset(signupBackground.image)}')` }">
                <p class="credit">
                    {{ $t("home.folio.artwork_by") }} <ProLink :href="signupBackground.link" target="_blank">{{ signupBackground.artist }}</ProLink>
                </p>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/Modal";
import ProInput from "@/components/ProInput";
import Alert from "@/components/Alert";

export default {
    components: { Modal, ProInput, Alert },

    data: () => ({
        username: "",
        password: "",
        submitting: false,
        errorMessage: null,
    }),

    methods: {
        ...mapActions({
            attemptLogin: "auth/attemptLogin",
            close: "auth/closeLoginModal",
        }),

        async login() {
            this.errorMessage = null;
            this.submitting = true;

            try {
                await this.attemptLogin({
                    username: this.username,
                    password: this.password,
                });

                this.close();
            } catch (error) {
                this.submitting = false;
                this.errorMessage = this.$t("auth.validation_error");

                const passwordField = this.$refs.passwordinput.$el.querySelector("input");
                passwordField.select();
                passwordField.focus();
            }
        },

        reset() {
            this.username = "";
            this.password = "";
            this.submitting = false;
            this.errorMessage = null;

            this.close();
        },

        register(state) {
            this.$store.dispatch("auth/closeLoginModal");

            this.$nextTick(() => {
                this.$store.dispatch("auth/openRegisterModal");
            });
        },
    },

    computed: {
        ...mapGetters({
            open: "auth/isLoginModalOpen",
        }),
        signupBackground() {
            const art = this.$t("global.login_artworks");
            return art[Math.floor(Math.random() * art.length)];
        },
    },

    watch: {
        open(val) {
            if (val) {
                this.$refs.modal.open();
            } else {
                this.$refs.modal.close();
            }
        },
    },
};
</script>
