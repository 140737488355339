<template>
    <div v-bind="$attrs" class="locale-dropdown">
        <ProIcon icon="lang" />
        {{ currentLocale.name }}
        <ProIcon icon="arrow-down" style="opacity: 0.5; width: 0.65em" />
        <label for="i18n-language-select" class="sr-only">{{ $t("global.language") }}</label>
        <select id="i18n-language-select" :value="currentLocale.code" @change="updateLocale">
            <option v-for="locale in availableLocales" :value="locale.code">{{ locale.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
    computed: {
        availableLocales() {
            return this.$i18n.locales;
        },
        currentLocale() {
            return this.$i18n.locales.find((l) => l.code == this.$i18n.locale);
        },
    },

    methods: {
        updateLocale(e) {
            const locale = e.target.value;
            this.$store.dispatch("i18nPreference/savePreferredLocale", locale);
            this.$router.replace({ path: this.switchLocalePath(locale) });
        },
    },
};
</script>
