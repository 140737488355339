<template>
    <div class="pro-anchor" :id="name">
        <slot />
        <p v-if="$slots.default == 'undefined'" class="sr-only">Section: {{ name }}</p>
    </div>
</template>

<script>
export default {
    name: "ProAnchor",

    data: () => {
        return {
            inUse: false,
        };
    },

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    mounted() {
        if (this.$route.hash.length > 0) {
            this.inUse = this.$el.id == this.$route.hash.substring(1);
        }
    },
};
</script>
