<template>
    <section class="alerts-container">
        <Alert v-for="alert in alerts" :key="alert.id" v-bind="alert" auto-open />
    </section>
</template>

<script>
import Alert from "@/components/Alert";
import { mapGetters } from "vuex";

export default {
    components: { Alert },

    computed: {
        ...mapGetters({
            alerts: "alert/alerts",
        }),
    },
};
</script>
