import Vue from "vue";

Vue.directive(
    "show-on-scroll",
    (() => {
        let el = null;
        const set = () => {
            if (window.scrollY > 100) {
                el.setAttribute("data-show", "");
            } else {
                el.removeAttribute("data-show");
            }
        };

        const jump = () => {
            if (window.scrollY < 101) {
                window.scrollTo({
                    top: 110,
                    left: 0,
                    behavior: "smooth",
                });
            }
        };

        return {
            inserted: function (element) {
                el = element;
                set();
                window.addEventListener("scroll", set, true);
                element.addEventListener("keydown", jump, true);
            },
            unmounted(element) {
                window.removeEventListener("scroll", set);
                element.removeEventListener("keydown");
            },
        };
    })()
);
