import axios from "axios";

export const state = () => ({
    positions: [],
    homePositions: null,
    position: null,
    positionCount: 0,
});

export const mutations = {
    setPositions(state, positions) {
        state.positions = positions;
    },
    addMorePositions(state, positions) {
        state.positions.push(...positions);
    },
    setHomePositions(state, homePositions) {
        state.homePositions = homePositions;
    },
    setPosition(state, position) {
        state.position = position;
    },
    setPositionCount(state, count) {
        state.positionCount = count;
    },
};

export const actions = {
    async fetchPositions(context, options) {
        try {
            const queryParams = new URLSearchParams({ ...options });

            let { data } = await this.$adminApi.get(`/careers/positions?${queryParams.toString()}`);
            context.commit("setPositions", data.data);

            return data;
        } catch (error) {
            console.log("Failed to fetch positions: " + error);
        }
    },
    async fetchMorePositions(context, options) {
        try {
            let { data } = await axios.get(options.cursor);
            context.commit("addMorePositions", data.data);

            return data;
        } catch (error) {
            console.log("Failed to fetch positions: " + error);
        }
    },
    async fetchHomePositions(context, options) {
        if (!context.getters.getHomePositions) {
            try {
                let { data } = await this.$adminApi.get(`/careers/positions?limit=5&only=slug,title`);
                context.commit("setHomePositions", data.data);
            } catch (error) {
                console.log("Failed to fetch home positions: " + error);
            }
        }
    },
    async fetchPosition(context, payload) {
        try {
            let { data } = await this.$adminApi.get(`/careers/positions/${payload}`);
            context.commit("setPosition", data[0]);
        } catch (error) {
            console.log("Failed to fetch position: " + error);
            throw error;
        }
    },
    async fetchPositionCount(context) {
        if (!context.getters.getPositionCount) {
            try {
                let { data } = await this.$adminApi.get(`/careers/positions?limit=99&only=id`);
                context.commit("setPositionCount", data.data.length ?? 0);
            } catch (error) {
                console.log("Failed to fetch home positions: " + error);
            }
        }
    },
};

export const getters = {
    getPositions(state) {
        return state.positions;
    },
    getHomePositions(state) {
        return state.homePositions;
    },
    getPosition(state) {
        return state.position;
    },
    getPositionCount(state) {
        return state.positionCount;
    },
};
