import { locales } from "../i18n.config";

const ROUTE_WHITELIST = ["procreate", "pocket", "dreams"];
const REGEX = new RegExp("\\b(?:" + locales.map((l) => l.code).join("|") + ")/\\b");

export default function ({ route, store }) {
    const path = route.path.replace(REGEX, "");
    const firstPathParam = path.split("/")?.[1];
    const product = ROUTE_WHITELIST.includes(firstPathParam) ? firstPathParam : null;

    store.dispatch("currentProduct/updateProduct", product);
}
