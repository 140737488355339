<template>
    <div id="layout-default" class="layout-default" data-focus-method="key" v-focus-method>
        <div id="page-top" tabindex="-1"></div>
        <header role="banner">
            <ProButton href="#page-content" @click.prevent="skipToContent" class="a11y-skip-link">{{ $t("a11y-labels.skip_to_content") }}</ProButton>
            <LocaleBanner />
            <MainSiteNav />
            <ProductSubNav v-if="currentProduct" :product="currentProduct" v-show-on-scroll />
        </header>
        <Nuxt nuxt-child-key="page" id="page-content" />

        <LoginModal />
        <RegsiterModal />

        <AlertsContainer />
        <ModalsContainer />
    </div>
</template>

<script>
import LocaleBanner from "@/components/LocaleBanner";
import MainSiteNav from "@/components/navigation/MainSiteNav";
import LoginModal from "~/components/LoginModal";
import RegsiterModal from "~/components/RegisterModal";
import ModalsContainer from "~/components/ModalsContainer";
import AlertsContainer from "~/components/AlertsContainer";

import ProductSubNav from "@/components/navigation/ProductSubNav";

import { findFirstContentElement, focusElement } from "~/utils";
import { metaTitles, metaDescriptions, metaImages, metaKeywords, metaTwitterCard } from "~/utils/meta";
import { mapGetters } from "vuex";

export default {
    components: { LocaleBanner, LoginModal, ModalsContainer, RegsiterModal, AlertsContainer, ProductSubNav, MainSiteNav },

    methods: {
        skipToContent() {
            const heading = findFirstContentElement(document.querySelector("#page-content"));
            if (heading) {
                focusElement(heading);
            } // in slow connection and handbook content hasn't loaded yet (mostly for handbook.)
        },
    },

    computed: {
        ...mapGetters({ isLoggedIn: "auth/isLoggedIn", currentProduct: "currentProduct/getCurrentProduct" }),
    },

    mounted() {
        if (!this.isLoggedIn) this.$store.dispatch("auth/fetchUser");
    },

    head() {
        const i18nSeo = this.$nuxtI18nSeo();

        return {
            htmlAttrs: {
                ...i18nSeo.htmlAttrs,
            },
            title: this.$t("meta.default.title"),
            meta: [
                ...metaTitles(this.$t("meta.default.title")),
                ...metaDescriptions(this.$t("meta.default.description")),
                ...metaImages(this.$asset(this.$t("meta.default.image"))),
                ...metaKeywords(this.$t("meta.default.keywords")),
                ...metaTwitterCard("summary_large_image"),
            ],
            link: [
                { rel: "shortcut icon", href: this.$asset("images/favicon/favicon.ico").default },
                { rel: "apple-touch-startup-image", href: this.$asset("images/favicon/apple-icon.png") },
                { rel: "apple-touch-icon", href: this.$asset("images/favicon/apple-icon.png") },
                { rel: "apple-touch-icon", sizes: "57x57", href: this.$asset("images/favicon/apple-icon-57x57.png") },
                { rel: "apple-touch-icon", sizes: "60x60", href: this.$asset("images/favicon/apple-icon-60x60.png") },
                { rel: "apple-touch-icon", sizes: "72x72", href: this.$asset("images/favicon/apple-icon-72x72.png") },
                { rel: "apple-touch-icon", sizes: "76x76", href: this.$asset("images/favicon/apple-icon-76x76.png") },
                { rel: "apple-touch-icon", sizes: "114x114", href: this.$asset("images/favicon/apple-icon-114x114.png") },
                { rel: "apple-touch-icon", sizes: "120x120", href: this.$asset("images/favicon/apple-icon-120x120.png") },
                { rel: "apple-touch-icon", sizes: "152x152", href: this.$asset("images/favicon/apple-icon-152x152.png") },
                { rel: "icon", type: "image/png", sizes: "192x192", href: this.$asset("images/favicon/android-icon-192x192.png") },
                { rel: "icon", type: "image/png", sizes: "32x32", href: this.$asset("images/favicon/favicon-32x32.png") },
                { rel: "icon", type: "image/png", sizes: "96x96", href: this.$asset("images/favicon/favicon-96x96.png") },
                { rel: "icon", type: "image/png", sizes: "16x16", href: this.$asset("images/favicon/favicon-16x16.png") },
            ],
        };
    },
};
</script>
