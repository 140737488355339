export default function ({ store, route }) {
    const shouldShowLocaleBanner = (route) => {
        const options =
            route.matched.length > 0
                ? route.matched.map((r) => {
                      return r.components.default.options;
                  })[0]
                : null;

        if (options) {
            return options.shouldShowLocaleBanner ?? true;
        }
        return true;
    };

    store.commit("i18nPreference/setShouldShowLocaleBanner", shouldShowLocaleBanner(route));
}
