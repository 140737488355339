<template>
    <Modal ref="modal" @closed="reset" class="register-modal">
        <form
            v-if="!accountCreated"
            class="register-modal__form"
            :class="{ 'register-modal__form--submitting': submitting }"
            @submit.prevent="register"
            aria-live="assertive"
        >
            <div class="register-modal__copy">
                <h2 class="text-heading text-heading--sm" v-html="$t('global.register_account.heading')"></h2>
                <i18n path="global.register_account.intro" tag="p" class="text-body">
                    <template v-slot:folio>
                        <ProLink href="https://folio.procreate.com" target="_blank" style="white-space: nowrap">Procreate Folio</ProLink>
                    </template>
                    <template v-slot:privacy>
                        <ProLink :to="{ name: 'privacy-policy' }" target="_blank" style="white-space: nowrap">{{ $t("global.privacy_policy") }}</ProLink>
                    </template>
                </i18n>
            </div>

            <div ref="errors" class="register-modal__errors" v-show="Object.keys(errors).length">
                <h4 class="text-heading text-heading--xs">{{ $t("global.register_account.errors") }}</h4>
                <ul>
                    <li v-for="(error, field) in errors" :key="field">{{ error[0] }}</li>
                </ul>
            </div>

            <div class="register-modal__fields">
                <ProInput
                    id="register-email"
                    name="email"
                    autocomplete="email"
                    required
                    v-model="form.email"
                    :placeholder="$t('global.register_account.email_placeholder')"
                    :label="$t('global.register_account.email')"
                    type="email"
                    autofocus
                    :errors="errors.email"
                    square
                />
                <ProInput
                    id="register-username"
                    name="username"
                    autocomplete="none"
                    required
                    v-model="form.username"
                    :placeholder="$t('global.register_account.username_placeholder')"
                    :label="$t('global.register_account.username')"
                    type="text"
                    :errors="errors.username"
                    square
                />
                <ProInput
                    required
                    v-model="form.firstname"
                    :placeholder="$t('global.register_account.fname_placeholder')"
                    :label="$t('global.register_account.fname')"
                    type="text"
                    :errors="errors.firstname"
                    square
                />
                <ProInput
                    required
                    v-model="form.lastname"
                    :placeholder="$t('global.register_account.lname_placeholder')"
                    :label="$t('global.register_account.lname')"
                    type="text"
                    :errors="errors.lastname"
                    square
                />
                <ProInput
                    id="new-password"
                    autocomplete="new-password"
                    name="new-password"
                    minlength="4"
                    required
                    v-model="form.password"
                    :placeholder="$t('global.register_account.pass_placeholder')"
                    :label="$t('global.register_account.pass')"
                    type="password"
                    :errors="errors.password"
                    square
                />
                <ProInput
                    id="confirm-password"
                    autocomplete="new-password"
                    name="confirm-password"
                    required
                    v-model="form.password2"
                    :placeholder="$t('global.register_account.pass1')"
                    :label="$t('global.register_account.pass1')"
                    type="password"
                    square
                />
                <ProInput square required v-model="form.dob" :label="$t('global.register_account.dob')" type="date" :errors="errors.dob" />
                <div class="register-modal__terms">
                    <div class="register-modal__terms-wrapper">
                        <i18n path="global.register_account.t_and_c" tag="label" for="register-terms">
                            <template v-slot:terms>
                                <ProLink :to="{ name: 'terms-conditions' }" target="_blank" style="white-space: nowrap">{{
                                    $t("global.terms_conditions")
                                }}</ProLink>
                            </template>
                        </i18n>
                        <ProSwitch required large v-model="form.terms" id="register-terms" />
                    </div>
                    <small v-if="errors.terms" class="register-modal__terms-error">{{ errors.terms[0] }}</small>
                </div>
            </div>

            <div class="register-modal__actions">
                <ProButton v-fathom-event:click="['J8BT4BVW']" class="login-btn" pill variant="primary" type="submit" :loading="submitting">{{
                    $t("global.register_account.signup")
                }}</ProButton>
            </div>

            <div class="register-modal__footer">
                <p class="text-body">
                    {{ $t("global.have_procreate_id") }} <button type="button" @click="login">{{ $t("global.login") }}</button>
                </p>
            </div>
        </form>

        <div v-if="accountCreated" aria-live="assertive" class="register-modal__success">
            <h3 class="text-heading text-heading--sm">{{ $t("global.register_account.success_heading") }}</h3>
            <p class="text-body" v-html="$t('global.register_account.success_body')"></p>
            <div class="register-modal__success-actions">
                <ProButton variant="primary" pill @click="close">{{ $t("global.register_account.success_accept") }}</ProButton>
                <ProButton variant="muted" :action="resendConfrimationEmail">{{ $t("global.resend_verification_link") }}</ProButton>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focusElement, findFirstContentElement } from "~/utils";
import Modal from "~/components/Modal";
import ProInput from "@/components/ProInput";
import ProSwitch from "@/components/ProSwitch";

const defaultForm = {
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    password2: "",
    email: "",
    dob: "",
    terms: false,
};

export default {
    components: { Modal, ProInput, ProSwitch },

    data: () => ({
        submitting: false,
        errorMessage: null,
        form: { ...defaultForm },
        errors: {},
        accountCreated: false,
    }),

    methods: {
        ...mapActions({
            close: "auth/closeRegisterModal",
        }),

        reset() {
            this.form = { ...defaultForm };
            this.submitting = false;
            this.errorMessage = null;
            this.errors = {};
            this.accountCreated = false;

            this.close();
        },

        login() {
            this.$store.dispatch("auth/closeRegisterModal");

            this.$nextTick(() => {
                this.$store.dispatch("auth/openLoginModal");
            });
        },

        async register() {
            let form = this.form;
            form.lang = this.$i18n.locale;
            this.submitting = true;
            this.errors = {};

            try {
                let { data } = await this.$folioApiRequest("publicBetaRegister", form, { authenticate: false });

                if (data.success) {
                    this.submitting = false;
                    this.accountCreated = true;
                    this.$store.dispatch("auth/persistUser", data.user);
                } else {
                    this.errors = data.errors;
                    this.submitting = false;

                    // If there are errors, move focus back to the top of the form
                    if (Object.keys(this.errors).length) {
                        this.focusErrors();
                    }
                }
            } catch (error) {
                this.submitting = false;
                this.$alert({
                    title: this.$t("support.error_modal_title"),
                    message: this.$t("support.error_modal_message"),
                });

                console.log("Failed to register: " + error);
            }
        },

        async resendConfrimationEmail() {
            try {
                await this.$store.dispatch("auth/resendConfirmationEmail", { lang: this.$i18n.locale });
                this.$alert({
                    title: this.$t("global.resend_email_alert.success_title"),
                    message: this.$t("global.resend_email_alert.success_message"),
                    type: "success",
                });
            } catch (e) {
                this.$alert({
                    title: this.$t("global.resend_email_alert.error_title"),
                    message: this.$t("global.resend_email_alert.error_message"),
                });
            }
        },

        focusErrors() {
            this.$nextTick(() => {
                const el = findFirstContentElement(this.$refs.errors);
                focusElement(el);
            });
        },
    },

    computed: {
        ...mapGetters({
            open: "auth/isRegisterModalOpen",
            user: "auth/getUser",
        }),
    },

    watch: {
        open(val) {
            if (val) {
                this.$refs.modal.open();
            } else {
                this.$refs.modal.close();
            }
        },
    },
};
</script>
