<template>
    <div v-if="isVisible" class="locale-banner" :lang="suggestedLocale.iso" data-nosnippet>
        {{ /* `data-nosnippet` is to stop search engines from showing this block in search results */ }}
        <div class="locale-banner__container container">
            <div class="locale-banner__message">
                <p class="text-body" v-html="$t('message', suggestedLocale.code)"></p>
            </div>
            <form class="locale-banner__form" @submit.prevent="updateLocale">
                <ProSelect
                    ref="select"
                    :value="suggestedLocale?.code ?? null"
                    :label="$t('lang_select_label', suggestedLocale.code)"
                    labelHidden
                    class="locale-banner__select input-group--thinner"
                    required
                >
                    <option disabled :value="null">{{ $t("lang_select_fallback", suggestedLocale.code) }}</option>
                    <option v-for="l in $i18n.locales" :key="l.code" :value="l.code">{{ l.name }}</option>
                </ProSelect>
                <ProButton class="locale-banner__submit" type="submit" pill variant="primary" :aria-label="$t('button_label', suggestedLocale.code)"
                    ><span>{{ $t("button_display", suggestedLocale.code) }}</span
                    ><ProIcon icon="arrow-long-right"
                /></ProButton>
            </form>
            <ProButton @click="close()" :aria-label="$t('hide_banner', suggestedLocale.code)" class="locale-banner__close">
                <ProIcon icon="modal-close" />
            </ProButton>
        </div>
    </div>
</template>

<i18n>
{
    "en": {
        "message": "Choose from the available options to see Procreate in your&nbsp;language.",
        "lang_select_fallback": "Choose a language",
        "lang_select_label": "Choose a language",
        "button_display": "Continue",
        "button_label": "Set language",
        "hide_banner": "Hide language suggestion banner"
    },

    "cn": {
        "message": "在现有选项中进行选择，并用你的语言查看 Procreate。",
        "lang_select_fallback": "选择语言",
        "lang_select_label": "选择语言",
        "button_display": "继续",
        "button_label": "设定语言",
        "hide_banner": "隐藏语言建议栏"
    },

    "jp": {
        "message": "Procreate のコンテンツをオプション内の言語から選んでください。",
        "lang_select_fallback": "言語を選択",
        "lang_select_label": "言語を選択",
        "button_display": "続ける",
        "button_label": "言語を選択",
        "hide_banner": "言語通知バナーを非表示"
    }
}
</i18n>

<script>
import ProSelect from "@/components/ProSelect";
import { mapGetters } from "vuex";

export default {
    components: {
        ProSelect,
    },

    computed: {
        ...mapGetters({
            suggestedLocale: "i18nPreference/getSuggestedLocale",
            dismissedLocales: "i18nPreference/getDismissedLocales",
            shouldShowLocaleBanner: "i18nPreference/getShouldShowLocaleBanner",
        }),

        isVisible() {
            return (
                this.suggestedLocale &&
                this.shouldShowLocaleBanner &&
                this.suggestedLocale.code !== this.$i18n.locale &&
                !this.dismissedLocales.includes(this.$i18n.locale)
            );
        },
    },

    methods: {
        updateLocale() {
            const locale = this.$refs.select.$refs.input.options[this.$refs.select.$refs.input.selectedIndex].value;
            this.$store.dispatch("i18nPreference/savePreferredLocale", locale);
            if (locale !== this.$i18n.locale) this.$router.replace({ path: this.switchLocalePath(locale) });
        },

        close() {
            this.$store.dispatch("i18nPreference/dismissLocale", this.$i18n.locale);
        },
    },
};
</script>
