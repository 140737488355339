<template>
    <footer class="site-footer">
        <div class="container" aria-hidden="true">
            <ProcreateWordmark class="footer-wordmark" />
            <p class="footer-byline text-heading">{{ $t("navigation-footer.byline") }}</p>
        </div>
        <nav class="container">
            <div class="site-footer__main desktop">
                <div v-for="(value, key) in mainLayout" :key="key" class="site-footer__group">
                    <div v-for="section in value" :key="section.name" class="site-footer__section">
                        <h3 :id="`footer-nav-${section.name}`" class="text-body">{{ section.label }}</h3>
                        <NavItemList :items="section.items" role="group" :aria-labelledby="`footer-nav-${section.name}`" />
                    </div>
                    <div v-if="key == 'supplemental'" class="site-footer__mini-navs mobile-only">
                        <NavItemList v-for="(section, index) in miniLayout" :key="`sup-mobile-${index}`" :items="section.items" role="group" />
                    </div>
                </div>
            </div>

            <div class="site-footer__mini">
                <div class="site-footer__acknow text-body">
                    <div>
                        <LocaleDropdown />
                    </div>
                    <span>{{ $t("navigation-footer.acknow") }}</span> <br />
                    {{ $t("navigation-footer.copyright_message", { year: new Date().getFullYear() }) }}
                </div>
                <div class="site-footer__mini-navs">
                    <NavItemList v-for="(section, index) in miniLayout" :key="`sup-mobile-${index}`" :items="section.items" role="group" />
                </div>
            </div>
        </nav>
    </footer>
</template>

<script>
import LocaleDropdown from "@/components/LocaleDropdown";
import NavItemList from "@/components/navigation/NavItemsList";
import ProcreateWordmark from "@/components/wordmarks/ProcreateWordmark";

export default {
    components: { NavItemList, LocaleDropdown, ProcreateWordmark },

    computed: {
        sections() {
            return {
                procreate: {
                    name: "procreate",
                    label: this.$t("navigation-procreate.main_link").product_title,
                    items: this.$t("navigation-procreate.menu_items"),
                },
                dreams: {
                    name: "dreams",
                    label: this.$t("navigation-dreams.main_link").product_title,
                    items: this.$t("navigation-dreams.menu_items"),
                },
                pocket: {
                    name: "pocket",
                    label: this.$t("navigation-pocket.main_link").product_title,
                    items: this.$t("navigation-pocket.menu_items"),
                },

                connect: {
                    name: "connect",
                    label: this.$t("navigation-sections.connect"),
                    items: this.$t("navigation-connect.menu_items"),
                },
                company: {
                    name: "company",
                    label: this.$t("navigation-sections.company"),
                    items: this.$t("navigation-company.menu_items"),
                },
                learning: {
                    name: "learning",
                    label: this.$t("navigation-sections.learning"),
                    items: this.$t("navigation-learning.menu_items"),
                },
                legal: {
                    name: "legal",
                    items: this.$t("navigation-footer.menu_items"),
                },
            };
        },

        mainLayout() {
            return {
                product: [this.sections.procreate, this.sections.dreams, this.sections.pocket],
                supplemental: [this.sections.company, this.sections.learning],
            };
        },

        miniLayout() {
            return [this.sections.connect, this.sections.legal];
        },
    },
};
</script>
