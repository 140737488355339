<template>
    <Page behind-nav>
        <section class="error-page">
            <div class="error-page__background">
                <img :src="$asset('videos/error/animation-video-nikko-poster.jpg')" class="rmnp-hidden" />
                <video
                    class="rm-hidden"
                    :src="$asset('videos/error/animation-video-nikko.mp4')"
                    :poster="$asset('videos/error/animation-video-nikko-poster.jpg')"
                    playsinline
                    muted
                    autoplay
                    loop
                    preload
                    aria-hidden="true"
                    disableremoteplayback
                    x-webkit-airplay="deny"
                ></video>
            </div>
            <div class="error-page__text container">
                <div v-if="error.statusCode === 404">
                    <p class="text-subheading">{{ $t("global.not_found_subheading") }}</p>
                    <h1 class="text-heading text-heading">{{ $t("global.not_found_heading") }}</h1>
                </div>
                <div v-else>
                    <p class="text-subheading">Error</p>
                    <h1 class="text-heading text-heading">Sorry, an error occured</h1>
                </div>
            </div>
        </section>
    </Page>
</template>

<script>
export default {
    name: "NotFound",
    props: ["error"],

    mounted() {
        if (this.error.statusCode == 404) fathom.trackEvent(`404-page-not-found`);
    },
};
</script>
