<template>
    <Modal ref="alert" class="alert" :dismissable="dismissable" @canceled="canceled" :show-close-button="false" v-on="$listeners">
        <slot v-if="$slots.header" name="header" />
        <div v-else :class="`alert__icon alert__icon--${type}`">
            <ProIcon v-if="type == 'error'" icon="warning" />
            <ProIcon v-if="type == 'success'" icon="success" />
        </div>
        <div class="alert__content">
            <h3 class="alert__title" v-if="title">{{ title }}</h3>
            <p class="alert__message" v-if="message">{{ message }}</p>
            <slot />
        </div>
        <div class="alert__footer">
            <slot v-if="$slots.footer" name="footer" />
            <div v-else class="alert__default-actions">
                <div v-if="cancellable">
                    <pro-button variant="cancel" pill @click="close(false)">{{ cancelTitle }}</pro-button>
                </div>
                <div>
                    <pro-button variant="primary" pill @click="close(true)">{{ actionTitle }}</pro-button>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
    components: { Modal },

    props: {
        title: String,
        message: String,

        type: {
            type: String,
            default: "error",
            validate: (v) => ["error", "success"].contains(v),
        },

        // Allows the alert to be canceled by clicking outside the alert window
        dismissable: {
            type: Boolean,
            default: true,
        },

        cancellable: {
            type: Boolean,
            default: false,
        },

        actionTitle: {
            type: String,
            default: function () {
                return this.$t("global.okay");
            },
        },

        cancelTitle: {
            type: String,
            default: function () {
                return this.$t("global.cancel");
            },
        },

        autoOpen: {
            type: Boolean,
        },

        callback: {
            type: Function,
        },
    },

    data: () => ({
        resolve: null,
    }),

    methods: {
        open() {
            this.$refs.alert.open();
            return new Promise((r) => (this.resolve = r));
        },

        close(result) {
            this.resolve(result);
            const promise = this.$refs.alert.close();
            if (this.callback && typeof this.callback === "function") this.callback(result, promise);
            return promise;
        },

        canceled(promise) {
            this.resolve(false);
            if (this.callback && typeof this.callback === "function") this.callback(false, promise);
            return promise;
        },
    },

    mounted() {
        if (this.autoOpen) {
            this.open();
        }
    },
};
</script>
