export const state = () => ({});

export const mutations = {};

export const actions = {
    async signup(context, payload) {
        try {
            return await this.$adminApi.post("/newsletter", {
                email: payload.email,
                tags: payload.tags,
                language: payload.language,
                recaptcha: payload.recaptcha,
            });
        } catch (error) {
            console.log("Failed to signup: " + error);
            throw error;
        }
    },
};

export const getters = {};
