import Vue from "vue";

Vue.directive("fathom-event", {
    inserted(element, binding, vnode) {
        let params = binding.value;
        if (binding.value) {
            element.addEventListener(binding.arg, () => {
                const eventID = params[0];
                const eventValue = params[1] ? params[1] : 0;
                try {
                    fathom.trackGoal(eventID, eventValue);
                } catch {
                    console.warn(`Fathom script was not found, unable to process event for: ${eventID}`);
                }
            });
        }
    },
});
