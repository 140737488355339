import locale7742780c from '../../lang/en-US'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"en","iso":"en-US","file":"en-US","name":"English (US)"},{"code":"cn","iso":"zh-CN","file":"zh-CN","countries":["CN","MO","TW","HK"],"name":"简体中文"},{"code":"jp","iso":"ja","file":"ja","countries":["JP"],"name":"日本語"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/home/runner/work/marketing/marketing/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://procreate.com",
  vuex: false,
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  defaultIso: "en-US",
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US","name":"English (US)"},{"code":"cn","iso":"zh-CN","file":"zh-CN","countries":["CN","MO","TW","HK"],"name":"简体中文"},{"code":"jp","iso":"ja","file":"ja","countries":["JP"],"name":"日本語"}],
  localeCodes: ["en","cn","jp"],
}

export const localeMessages = {
  'en-US': () => Promise.resolve(locale7742780c),
  'zh-CN': () => import('../../lang/zh-CN' /* webpackChunkName: "lang-zh-CN" */),
  'ja': () => import('../../lang/ja' /* webpackChunkName: "lang-ja" */),
}
