<script>
export default {
    name: "ProButton",

    props: {
        href: {
            type: String,
            default: null,
        },

        to: {
            type: Object,
            default: null,
        },

        el: {
            type: String,
        },

        action: {
            type: Function,
        },

        type: {
            type: String,
            default: "button",
        },

        variant: {
            type: String,
            default: "default",
        },

        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        block: {
            type: Boolean,
            default: false,
        },

        large: {
            type: Boolean,
            default: false,
        },

        pill: {
            type: Boolean,
            default: false,
        },

        outline: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        actionPending: false,
    }),

    computed: {
        as() {
            if (this.href) return "a";
            if (this.to) return "nuxt-link";
            if (this.el) return this.el;
            return "button";
        },
        props() {
            switch (this.as) {
                case "nuxt-link":
                    return { to: this.localePath(this.to) };
                default:
                    return {};
            }
        },
        attrs() {
            const attrs = { ...this.$attrs, disabled: this.disabled || this.loading || this.actionPending };

            switch (this.as) {
                case "a":
                    return { ...attrs, href: this.href };
                case "button":
                    return { ...attrs, type: this.type };
                default:
                    return attrs;
            }
        },
        listeners() {
            switch (this.as) {
                case "button":
                    return { click: this.performAction, ...this.$listeners };
                default:
                    return this.$listeners;
            }
        },
    },

    methods: {
        async performAction(e) {
            if (this.action && this.action instanceof Function) {
                const res = this.action();

                if (res instanceof Promise) {
                    this.actionPending = true;
                    await res;
                    this.actionPending = false;
                }
            }
        },
    },

    render(h) {
        return h(
            this.as,
            {
                attrs: this.attrs,
                props: this.props,
                on: this.listeners,
                class: {
                    "pro-btn": true,
                    [`btn-${this.variant}`]: true,
                    "btn-block": this.block,
                    "btn-lg": this.large,
                    "btn-loading": this.loading || this.actionPending,
                    "btn-pill": this.pill,
                    "btn-outline": this.outline,
                },
            },
            this.$slots.default
        );
    },
};
</script>
