<template>
    <div class="main-nav-dropdown-section">
        <button
            ref="floatingRef"
            @click="updateCurrentMenu(menuKey)"
            :id="toggleId"
            class="main-nav-dropdown-section__toggle"
            :aria-expanded="open ? true : false"
            :aria-controls="drawerId"
            type="button"
        >
            {{ label }}
            <ProIcon icon="select-arrow" :style="{ transform: `${!open ? 'none' : 'rotate(180deg)'}` }" />
        </button>

        <template v-if="shouldTransition">
            <transition name="menufade" mode="out-in">
                <div v-show="open" ref="floatingTarget" :id="drawerId" :aria-labelledby="toggleId" class="main-nav-dropdown-section__drawer">
                    <div class="arrow" ref="floatingArrow"></div>
                    <slot />
                </div>
            </transition>
        </template>
        <template v-else>
            <div v-show="open" :id="drawerId" :aria-labelledby="toggleId" class="main-nav-dropdown-section__drawer">
                <slot />
            </div>
        </template>
    </div>
</template>
<script>
import { arrow, computePosition, autoUpdate, offset, shift } from "@floating-ui/dom";

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        menuKey: {
            type: String,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        offset: {
            type: Number,
            default: -40,
        },
    },
    data: () => {
        return {
            shouldTransition: true,
            cleanup: null,
        };
    },
    computed: {
        toggleId() {
            return `main-nav-dropdown-section-toggle-${this.menuKey}`;
        },
        drawerId() {
            return `main-nav-dropdown-section-${this.menuKey}`;
        },
    },
    methods: {
        updateCurrentMenu(menuKey) {
            this.$emit("updateCurrentMenu", menuKey);
        },
        updatePosition() {
            const referenceEl = this.$refs.floatingRef;
            const arrowEl = this.$refs.floatingArrow;
            const targetEl = this.$refs.floatingTarget;

            computePosition(referenceEl, targetEl, {
                middleware: [offset({ mainAxis: 20, crossAxis: this.offset }), shift({ padding: 20 }), arrow({ element: arrowEl })],
            }).then(({ x, y, middlewareData }) => {
                Object.assign(targetEl.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });

                if (middlewareData.arrow) {
                    const { x, y } = middlewareData.arrow;

                    Object.assign(arrowEl.style, {
                        left: x != null ? `${x}px` : "",
                        top: y != null ? `${y}px` : "",
                    });
                }
            });
        },
        attachFloater() {
            if (this.open) {
                this.cleanup = autoUpdate(this.$refs.floatingRef, this.$refs.floatingTarget, this.updatePosition);
            }
        },
    },

    mounted() {
        this.attachFloater();
    },

    unmounted() {
        this.cleanup?.();
    },

    watch: {
        $route() {
            this.shouldTransition = false;
            setTimeout(() => {
                this.shouldTransition = true;
            }, 0);
        },
        open() {
            if (this.open) {
                this.attachFloater();
            } else {
                this.cleanup?.();
                this.cleanup = null;
            }
        },
    },
};
</script>

<style scoped>
.menufade-enter-active,
.menufade-leave-active {
    transition: opacity 200ms ease 50ms;
}

.menufade-enter,
.menufade-leave-to {
    opacity: 0;
    transition: opacity 250ms;
}

.arrow {
    display: block;
    position: absolute;
    top: -8px;
    right: 60px;
    width: 16px;
    height: 16px;
    border-width: 1px 1px 0 0;
    border-radius: 0px 3px 0px 0px;
    transform: rotate(-45deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    background-color: #141414;
}
</style>
