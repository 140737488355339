import { focusElement, findFirstContentElement } from "~/utils";

if (process.client) {
    if ("scrollRestoration" in window.history) {
        // reset scrollRestoration to auto when leaving page, allowing page reload
        // and back-navigation from other pages to use the browser to restore the
        // scrolling position.
        window.addEventListener("beforeunload", () => {
            window.history.scrollRestoration = "auto";
        });

        // Setting scrollRestoration to manual again when returning to this page.
        window.addEventListener("load", () => {
            window.setTimeout(() => {
                window.history.scrollRestoration = "manual";
            }, 300);
        });
    }

    if (window.location.hash) {
        try {
            document.querySelector(window.location.hash).scrollIntoView();
        } catch {
            console.warn(`Route Warning: Anchor [${window.location.hash}] doesn't exist on this page.`);
        }
    }
}

function getMatchedComponents(route, matches = false, prop = "components") {
    return Array.prototype.concat.apply(
        [],
        route.matched.map((m, index) => {
            return Object.keys(m[prop]).map((key) => {
                matches && matches.push(index);
                return m[prop][key];
            });
        })
    );
}

function shouldScrollToTop(route) {
    const pages = getMatchedComponents(route);

    // Find a page that defines a preference about scrollToTop
    const page = pages.find(({ options }) => options && options.scrollToTop);

    // If a page is found, return its preference
    if (page) {
        if (typeof page.options.scrollToTop == "function") {
            return page.options.scrollToTop(route);
        }

        return page.options.scrollToTop;
    }

    // Otherwise, scroll to top
    return true;
}

export default function (to, from, savedPosition) {
    let position = false;
    const isRouteChanged = to !== from;

    // savedPosition is only available for popstate navigations (back button)
    if (savedPosition) {
        position = savedPosition;
    } else if (isRouteChanged && shouldScrollToTop(to)) {
        position = { x: 0, y: 0 };
    }

    if (!isRouteChanged || (to.path === from.path && to.hash !== from.hash)) {
        window.$nuxt.$nextTick(() => window.$nuxt.$emit("triggerScroll"));
    }

    return new Promise((resolve) => {
        // wait for the out transition to complete (if necessary)
        window.$nuxt.$once("triggerScroll", () => {
            // coords will be used if no selector is provided,
            // or if the selector didn't match any element.
            if (to.hash) {
                try {
                    // Can't use `#xyz` here as querySelector doesn't support IDs with leading numbers
                    if (document.querySelector(to.hash)) {
                        window.setTimeout(() => {
                            document.querySelector(to.hash).scrollIntoView();

                            // Move focus to nearest element/
                            let firstElm = findFirstContentElement(document.querySelector(to.hash));
                            if (firstElm) {
                                focusElement(firstElm);
                            }
                        }, 0);
                    }
                } catch (e) {
                    console.warn("Failed to save scroll position.");
                }
            } else {
                window.$nuxt.$nextTick(() => {
                    document.querySelector("#page-top")?.focus();
                    document.activeElement?.blur();
                });
            }
            resolve(position);
        });
    });
}
