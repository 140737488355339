<template>
    <nav @keydown.esc="currentOpenSection = null" ref="mainNav" class="main-site-nav" v-trap="drawerOpen" :class="{ 'main-site-nav--drawer-open': drawerOpen }">
        <div class="container">
            <div class="main-site-nav__container">
                <div class="main-site-nav__logo">
                    <ProLink :to="{ path: '/' }" :title="`Procreate – ${$t('global.home')}`">
                        <ProcreateWordmark style="height: 25px" />
                    </ProLink>
                </div>
                <div class="main-site-nav__items">
                    <MainNavDropdownSection
                        :label="sections.apps.label"
                        :menuKey="sections.apps.key"
                        :open="currentOpenSection == sections.apps.key"
                        :offset="40"
                        @updateCurrentMenu="updateCurrentMenu"
                    >
                        <AppsDrawer />
                    </MainNavDropdownSection>

                    <MainNavDropdownSection
                        :label="sections.support.label"
                        :menuKey="sections.support.key"
                        :open="currentOpenSection == sections.support.key"
                        @updateCurrentMenu="updateCurrentMenu"
                    >
                        <NavItemList :items="sections.support.items" details iconIfExternal />
                    </MainNavDropdownSection>

                    <MainNavDropdownSection
                        :label="sections.explore.label"
                        :menuKey="sections.explore.key"
                        :open="currentOpenSection == sections.explore.key"
                        @updateCurrentMenu="updateCurrentMenu"
                    >
                        <NavItemList :items="sections.explore.items" details iconIfExternal />
                    </MainNavDropdownSection>
                </div>
                <button
                    @click="handleDrawer(!drawerOpen)"
                    class="main-site-nav__toggle"
                    :class="{ 'main-site-nav__toggle--open': drawerOpen }"
                    :aria-label="$t('a11y-labels.header.mobile_button')"
                >
                    <span></span>
                </button>
            </div>
        </div>

        <transition name="menumobilefade" mode="out-in">
            <div v-show="drawerOpen" class="main-site-nav__drawer">
                <div class="main-site-nav__drawer-container">
                    <div class="main-site-nav__drawer-section">
                        <span id="main-nav-drawer-apps" class="text-subheading">{{ sections.apps.label }}</span>
                        <ul class="main-site-nav__drawer-apps" role="group" aria-labelledby="main-nav-drawer-apps">
                            <li class="main-site-nav__drawer-app">
                                <ProLink :to="{ name: 'procreate' }">
                                    <AppBadge
                                        :icon="$asset('images/product-icons/procreate.png')"
                                        :productName="$t('navigation-procreate.main_link.product_title')"
                                        :productDetails="$t('navigation-procreate.main_link.description')"
                                    />
                                </ProLink>
                            </li>
                            <li class="main-site-nav__drawer-app">
                                <ProLink :to="{ name: 'dreams' }">
                                    <AppBadge
                                        :icon="$asset('images/product-icons/dreams.png')"
                                        :productName="$t('navigation-dreams.main_link.product_title')"
                                        :productDetails="$t('navigation-dreams.main_link.description')"
                                    />
                                </ProLink>
                            </li>
                            <li class="main-site-nav__drawer-app">
                                <ProLink :to="{ name: 'pocket' }">
                                    <AppBadge
                                        :icon="$asset('images/product-icons/pocket.png')"
                                        :productName="$t('navigation-pocket.main_link.product_title')"
                                        :productDetails="$t('navigation-pocket.main_link.description')"
                                    />
                                </ProLink>
                            </li>
                        </ul>
                    </div>

                    <div class="main-site-nav__drawer-section">
                        <span id="main-nav-drawer-explore" class="text-subheading">{{ sections.explore.label }}</span>
                        <NavItemList :items="sections.explore.items" role="group" aria-labelledby="main-nav-drawer-explore" />
                    </div>

                    <div class="main-site-nav__drawer-section">
                        <span id="main-nav-drawer-support" class="text-subheading">{{ sections.support.label }}</span>
                        <NavItemList :items="sections.support.items" role="group" aria-labelledby="main-nav-drawer-support" />
                    </div>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script>
import NavItemList from "@/components/navigation/NavItemsList";
import MainNavDropdownSection from "@/components/navigation/MainNavDropdownSection";
import AppsDrawer from "@/components/navigation/AppsDrawer";
import AppBadge from "@/components/navigation/AppBadge";
import ProcreateWordmark from "@/components/wordmarks/ProcreateWordmark.vue";

export default {
    components: {
        NavItemList,
        MainNavDropdownSection,
        AppsDrawer,
        AppBadge,
        ProcreateWordmark,
    },
    data: () => {
        return {
            currentOpenSection: null,
            drawerOpen: false,
        };
    },
    methods: {
        updateCurrentMenu(key) {
            if (this.currentOpenSection == key) {
                this.currentOpenSection = null;
            } else {
                this.currentOpenSection = key;
            }
        },
        setupResizeObserver() {
            this.observer = new ResizeObserver(([entry] = entries) => {
                const width = entry.contentRect.width;
                if (width >= 600) {
                    this.handleDrawer(false);
                } else {
                    this.currentOpenSection = null;
                }
            });

            this.observer.observe(this.$refs.mainNav);
        },

        handleDrawer(bool) {
            this.drawerOpen = bool;
        },

        handleFocus(e) {
            const focusInside = this.$el.contains(e.target);
            if (!focusInside && this.currentOpenSection) {
                this.updateCurrentMenu(null);
            }
        },

        async getCareersCount() {
            try {
                await this.$store.dispatch("employment/fetchPositionCount");
            } catch (e) {
                console.error(e);
            }
        },
    },

    computed: {
        sections() {
            return {
                apps: {
                    label: this.$t("navigation-sections.apps"),
                    key: "apps",
                },
                support: {
                    label: this.$t("navigation-sections.learn"),
                    key: "support",
                    items: this.$t("navigation-support.menu_items"),
                },
                explore: {
                    label: this.$t("navigation-sections.company"),
                    key: "explore",
                    items: this.$t("navigation-explore.menu_items"),
                },
            };
        },
    },

    mounted() {
        this.setupResizeObserver();
        this.getCareersCount();

        // Hide menu on scroll
        window.addEventListener("scroll", () => {
            if (window.scrollY > 75) {
                this.updateCurrentMenu(null);
            }
        });
    },
    unmounted() {
        window.removeEventListener("scroll");
    },
    watch: {
        currentOpenSection(key) {
            if (key != null) {
                document.addEventListener("click", this.handleFocus);
            } else {
                document.removeEventListener("click", this.handleFocus);
            }
        },

        drawerOpen() {
            this.$nextTick(() => (document.body.style.overflow = this.drawerOpen ? "hidden" : null));
        },

        $route() {
            this.updateCurrentMenu(null);
            this.handleDrawer(false);
        },
    },
};
</script>

<style scoped>
.menumobilefade-enter-active,
.menumobilefade-leave-active {
    transition: opacity 250ms;
}

.menumobilefade-enter,
.menumobilefade-leave-to {
    opacity: 0;
}
</style>
