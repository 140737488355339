import Vue from "vue";
import ProButton from "~/components/ProButton";
import ProLink from "~/components/ProLink";
import ProIcon from "~/components/ProIcon";
import Page from "~/components/Page";
import ProAnchor from "~/components/ProAnchor";

// Components that are registered globaly.
[ProButton, ProLink, ProIcon, Page, ProAnchor].forEach((Component) => {
    Vue.component(Component.name, Component);
});
