<template>
    <input
        v-bind="$attrs"
        type="checkbox"
        class="pro-switch"
        :class="{ 'pro-switch--large': large }"
        :checked="value"
        @input="$emit('input', $event.target.checked)"
    />
</template>

<script>
export default {
    props: {
        value: Boolean,
        large: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
