import axios from "axios";
import cookies from "js-cookie";
import { isString } from "lodash";
import { parseISO, format } from "date-fns";

export const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;

    return () => {
        const context = this;
        const args = arguments;

        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(() => {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

export const debounce = (func, wait, immediate) => {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const scrollTo = (element) => {
    element.scrollIntoView();
};

export const isMobile = () => {
    if (process.client) {
        var smBreakpoint = window.getComputedStyle(document.querySelector("html")).getPropertyValue("--sm-breakpoint");
        const mq = window.matchMedia(`(max-width: ${smBreakpoint.trim()}`);
        if (mq.matches) {
            return true;
        }
    }
    return false;
};

// From: https://github.com/lukeed/uid#readme
// Not cryptographically secure
export const uid = (length = 11) => {
    let IDX = 36,
        HEX = "",
        str = "",
        num = length;
    while (IDX--) HEX += IDX.toString(36);
    while (num--) str += HEX[(Math.random() * 36) | 0];
    return str;
};

/*
    Used to find the first child element that is suitable for screen-readers. May be expanded to
    more than just headings in the future.
*/
export const findFirstContentElement = (element) => {
    return [...element.querySelectorAll("h1, h2, h3, h4, h5, h6")].find((el) => {
        return window.getComputedStyle(el).display !== "none";
    });
};

/*
    Focus any element, even content that isn't usually focusable. Useful for moving tabindex and
    screenreaders to particluar elements.
*/
export const focusElement = (element) => {
    const focusable = "input, select, textarea, button, a[href], area[href], object, [tabindex]";
    if (element && element.matches(focusable)) {
        setTimeout(() => element.focus());
        return;
    }
    element.setAttribute("tabindex", "-1");
    setTimeout(() => element.focus());
    element.addEventListener("blur", () => element.removeAttribute("tabindex"), { once: true });
};

export const deepClone = (inObject) => {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepClone(value);
    }

    return outObject;
};

export const slugify = (text) => {
    return text
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-"); // Replace multiple - with single -
};

export const copyToClipboard = (string) => {
    return navigator.clipboard
        .writeText(string)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};

export const bucketImage = (image, params = { width: 1000 }) => {
    let path = isString(image) ? image : image.path;
    path = path.replace("https://images.procreate.art/", "");

    let parts = ["format:jpg"];
    for (var key in params) {
        if (params.hasOwnProperty(key) && params[key]) {
            parts.push([key, params[key]].join(":"));
        }
    }

    let format = parts.join(",");

    //params need to be added if we extend in future
    return `https://optimised.savage.si/${format}/${path}`;
};

export const formatDateForLocale = (date, iso, style = "long") => {
    //check to see if dateStyle option is supported, if not return formatted en-US date.
    const isDateStyleSupported = new Intl.DateTimeFormat("en-US", { dateStyle: style }).resolvedOptions().dateStyle;
    if (isDateStyleSupported == undefined) return format(parseISO(date), "MMMM d',' yyyy");

    let localeFormat = new Intl.DateTimeFormat(iso, { dateStyle: style });

    return localeFormat.format(new Date(date));
};

export const videoPlayable = async (video) => {
    return await new Promise((resolve) => {
        if (video.readyState == 4) resolve();
        else video.addEventListener("canplaythrough", resolve);
    });
};
