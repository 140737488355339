<template>
    <div class="menu-apps-drawer">
        <span class="sr-only" id="menu-apps-drawer-title">{{ this.$t("navigation-sections.our_apps") }}</span>
        <!-- smart buttons -->
        <!-- <div @keydown.down="updateActiveTab" @keydown.up="updateActiveTab" class="menu-apps-drawer__products" role="tablist"
            ref="tabs" aria-labelledby="menu-apps-drawer-title">
            <button @click="updateActiveProduct('ipad')" type="button" role="tab" data-app="ipad"
                :tabindex="activeProduct == 'ipad' ? 0 : -1" id="menu-apps-drawer-tab-procreate"
                class="menu-apps-drawer__product" :class="{ 'active': activeProduct == 'ipad' }"
                aria-controls="menu-apps-drawer-menu-procreate" :aria-label="apps.procreate.badge.product_title">
                <AppBadge aria-hidden="true" :icon="$asset('images/product-icons/procreate.png')"
                    :productName="apps.procreate.badge.product_title" :productDetails="apps.procreate.badge.description" />
            </button>
            <button @click="updateActiveProduct('dreams')" type="button" role="tab" data-app="dreams"
                :tabindex="activeProduct == 'dreams' ? 0 : -1" id="menu-apps-drawer-tab-dreams"
                class="menu-apps-drawer__product" :class="{ 'active': activeProduct == 'dreams' }"
                :aria-label="apps.dreams.badge.product_title" aria-controls="menu-apps-drawer-menu-dreams">
                <AppBadge aria-hidden="true" :icon="$asset('images/product-icons/dreams.png')"
                    :productName="apps.dreams.badge.product_title" :productDetails="apps.dreams.badge.description" />
            </button>
            <button @click="updateActiveProduct('pocket')" type="button" role="tab" data-app="pocket"
                :tabindex="activeProduct == 'pocket' ? 0 : -1" id="menu-apps-drawer-tab-pocket"
                class="menu-apps-drawer__product" :class="{ 'active': activeProduct == 'pocket' }"
                :aria-label="apps.pocket.badge.product_title" aria-controls="menu-apps-drawer-menu-pocket">
                <AppBadge aria-hidden="true" :icon="$asset('images/product-icons/pocket.png')"
                    :productName="apps.pocket.badge.product_title" :productDetails="apps.pocket.badge.description" />
            </button>
        </div> -->

        <!-- smart tabs -->
        <!-- <div class="menu-apps-drawer__product-menus">
            <div v-if="activeProduct == 'ipad'" role="tabpanel" id="menu-apps-drawer-menu-procreate"
                aria-labelledby="menu-apps-drawer-tab-procreate" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.procreate.items" details />
            </div>
            <div v-if="activeProduct == 'dreams'" role="tabpanel" id="menu-apps-drawer-menu-dreams"
                aria-labelledby="menu-apps-drawer-tab-dreams" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.dreams.items" details />
            </div>
            <div v-if="activeProduct == 'pocket'" role="tabpanel" id="menu-apps-drawer-menu-pocket"
                aria-labelledby="menu-apps-drawer-tab-pocket" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.pocket.items" details />
            </div>
        </div> -->

        <!-- dumb buttons -->
        <div class="menu-apps-drawer__products">
            <ProLink :to="{ name: 'procreate' }" class="menu-apps-drawer__product" :aria-label="apps.procreate.badge.product_title">
                <AppBadge
                    aria-hidden="true"
                    :icon="$asset('images/product-icons/procreate.png')"
                    :productName="apps.procreate.badge.product_title"
                    :productDetails="apps.procreate.badge.description"
                />
            </ProLink>
            <ProLink :to="{ name: 'dreams' }" class="menu-apps-drawer__product" :aria-label="apps.dreams.badge.product_title">
                <AppBadge
                    aria-hidden="true"
                    :icon="$asset('images/product-icons/dreams.png')"
                    :productName="apps.dreams.badge.product_title"
                    :productDetails="apps.dreams.badge.description"
                />
            </ProLink>
            <ProLink :to="{ name: 'pocket' }" class="menu-apps-drawer__product" :aria-label="apps.pocket.badge.product_title">
                <AppBadge
                    aria-hidden="true"
                    :icon="$asset('images/product-icons/pocket.png')"
                    :productName="apps.pocket.badge.product_title"
                    :productDetails="apps.pocket.badge.description"
                />
            </ProLink>
        </div>

        <!-- dumb tabs -->
        <div class="menu-apps-drawer__product-menus">
            <div v-if="currentProduct == 'procreate'" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.procreate.items.filter((item) => item.in_main_nav !== false)" details />
            </div>
            <!-- <div v-if="currentProduct == 'dreams'" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.dreams.items" details />
            </div> -->
            <!-- <div v-if="currentProduct == 'pocket'" class="menu-app-drawer__product-menu">
                <NavItemList :items="apps.pocket.items.filter((item) => item.in_main_nav !== false)" details />
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppBadge from "@/components/navigation/AppBadge.vue";
import NavItemList from "@/components/navigation/NavItemsList";

export default {
    components: { AppBadge, NavItemList },

    data: () => {
        return {
            activeProduct: null,
        };
    },

    computed: {
        ...mapGetters({ currentProduct: "currentProduct/getCurrentProduct" }),

        apps() {
            return {
                procreate: {
                    badge: this.$t("navigation-procreate.main_link"),
                    items: this.$t("navigation-procreate.menu_items"),
                },
                dreams: {
                    badge: this.$t("navigation-dreams.main_link"),
                    items: this.$t("navigation-dreams.menu_items"),
                },
                pocket: {
                    badge: this.$t("navigation-pocket.main_link"),
                    items: this.$t("navigation-pocket.menu_items"),
                },
            };
        },
    },

    methods: {
        updateActiveTab(e) {
            e.preventDefault();
            const tabs = [...this.$refs.tabs.children];
            const currentTabIndex = tabs.indexOf(e.target);
            let updatedIndex = currentTabIndex;
            if (e.key == "ArrowDown") {
                updatedIndex++;
                updatedIndex = updatedIndex == tabs.length ? 0 : updatedIndex;
            }
            if (e.key == "ArrowUp") {
                updatedIndex--;
                updatedIndex = updatedIndex == -1 ? tabs.length - 1 : updatedIndex;
            }
            tabs[updatedIndex].click();
            tabs[updatedIndex].focus();
        },

        updateActiveProduct(product) {
            this.activeProduct = product;
        },
    },

    mounted() {
        this.activeProduct = this.currentProduct ?? "ipad";
    },
};
</script>
