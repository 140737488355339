import cookies from "js-cookie";

export const state = () => ({
    group: "handbook",
    current_product: "procreate",
    current_version: "5.3",
    products: [
        {
            slug: "procreate",
            title: "Procreate®",
            versions: [
                { slug: "5.3", title: "5.3", group_version: "procreate-53", dynamic_meta: true, languages: { en: "en-US", jp: "ja", cn: "zh-CN" } },
                { slug: "5.2", title: "5.2", group_version: "procreate-52", dynamic_meta: true, languages: { en: "en-US", jp: "ja", cn: "zh-CN" } },
                { slug: "5.1", title: "5X", group_version: "procreate-5x", dynamic_meta: false, languages: { en: "en-US", jp: "ja", cn: "zh-CN" } },
                { slug: "5.0", title: "5.0", group_version: "procreate-5", dynamic_meta: false, languages: { en: "en-US", jp: "ja", cn: "zh-CN" } },
            ],
        },
        {
            slug: "pocket",
            title: "Procreate® Pocket",
            versions: [{ slug: "4.0", title: "4.0", group_version: "procreate-pocket", dynamic_meta: true, languages: { en: "en-US", jp: "ja", cn: "zh-CN" } }],
        },
    ],
    pages: [],
    page: null,
    poi: null,
    search_query: "",
    search_results: null,
    light_mode: false,
});

export const mutations = {
    setCurrentVersion(state, version) {
        state.current_version = version;
    },

    setCurrentProduct(state, product) {
        state.current_product = product;
    },

    setPages(state, pages) {
        state.pages = pages;
    },

    setPage(state, page) {
        state.page = page;
    },

    setSearchQuery(state, query) {
        state.search_query = query;
    },

    setSearchResults(state, search_results) {
        state.search_results = search_results;
    },

    setPoi(state, poi) {
        state.poi = poi;
    },

    setLightMode(state, mode) {
        if (process.client) {
            if (mode) {
                const options = {
                    expires: 365,
                    path: "/",
                    domain: this.$isDev() ? null : ".procreate.com",
                    secure: window.location.protocol === "https:" ? true : false,
                    sameSite: "Strict",
                };
                cookies.set("light-mode", true, options);
            } else {
                const options = {
                    path: "/",
                    domain: this.$isDev() ? null : ".procreate.com",
                };
                cookies.remove("light-mode", options);
            }
        }

        state.light_mode = mode;
    },
};

export const actions = {
    async fetchPages(context, payload = {}) {
        if (payload.version && payload.version != context.state.current_version) {
            context.commit("setCurrentVersion", payload.version);
        }

        try {
            let { data } = await this.$adminApi.get(
                "/page/" +
                    context.state.group +
                    "/" +
                    context.getters.getCurrentVersion.group_version +
                    "?lang=" +
                    context.getters.getSupportedLanguageForLanguageCode(this.$i18n.locale)
            );
            context.commit("setPages", data.pages);
        } catch (error) {
            console.log("Failed to fetch handbook pages: " + error);
            throw error;
        }
    },

    async fetchPage(context, payload) {
        let response;

        try {
            response = await this.$adminApi.get(
                "/page/" +
                    context.state.group +
                    "/" +
                    context.getters.getCurrentVersion.group_version +
                    "/" +
                    payload.path +
                    "?lang=" +
                    context.getters.getSupportedLanguageForLanguageCode(this.$i18n.locale)
            );
            context.commit("setPage", response.data.page);
        } catch (error) {
            console.log("Failed to fetch handbook page: " + error);
            throw error;
        }

        return response.data.page;
    },

    async fetchSearchResults(context, payload) {
        context.commit("setSearchQuery", payload || "");

        if (payload && payload.length > 1) {
            try {
                let { data } = await this.$adminApi.get(
                    "/page/" +
                        context.state.group +
                        "/" +
                        context.getters.getCurrentVersion.group_version +
                        "/search?q=" +
                        encodeURIComponent(payload) +
                        "&lang=" +
                        context.getters.getSupportedLanguageForLanguageCode(this.$i18n.locale)
                );
                context.commit("setSearchResults", data.results);
            } catch (error) {
                context.commit("setSearchResults", []);
                console.log("Failed to fetch handbook search results: " + error);
                throw error;
            }
        }
    },
};

export const getters = {
    getDefaultVersion(state, getters) {
        return getters.getCurrentProduct.versions[0];
    },

    getDefaultProduct(state, getters) {
        return getters.getCurrentProduct;
    },

    getCurrentProduct(state) {
        return state.products.find((product) => product.slug === state.current_product);
    },

    getProducts(state, getters) {
        return state.products;
    },

    getProduct: (state, getters) => (slug) => {
        return state.products.find((product) => product.slug === slug);
    },

    getCurrentVersion(state, getters) {
        const version = getters.getCurrentProduct.versions.find((version) => version.slug === state.current_version);
        return version;
    },

    getVersions(state, getters) {
        return getters.getCurrentProduct.versions;
    },

    getVersion: (state, getters) => (slug) => {
        return getters.getCurrentProduct.versions.find((version) => version.slug === slug);
    },

    getIsLatestVersion(state, getters) {
        return state.current_version === getters.getCurrentProduct.versions[0].slug;
    },

    getSupportedLanguageForLanguageCode: (state, getters) => (language_code) => {
        return getters.getCurrentVersion.languages[language_code] ?? "en";
    },

    getPages(state) {
        return state.pages;
    },

    getPage(state) {
        return state.page;
    },

    getPoi(state) {
        return state.poi;
    },

    getIsSearching(state) {
        return state.search_query.length > 1;
    },

    getSearchQuery(state) {
        return state.search_query;
    },

    getSearchResults(state) {
        return state.search_results;
    },

    getActivePages(state) {
        if (!state.page) return [];

        let pages = [state.page.id];

        const findParents = (page) => {
            if (page.parent) {
                pages.unshift(page.parent.id);
                findParents(page.parent);
            }
        };

        findParents(state.page);

        return pages;
    },

    getFlatNav(state) {
        const flattenDescendants = function (pages = [], path = "") {
            if (pages === undefined) return []; // escape if no pages / slow network (page won't load anyway)

            return pages.reduce((flat, page) => {
                const { descendants, ...p } = page;
                p.path = `${path}${page.slug}`;
                flat.push(p);

                if (page.descendants.length) {
                    flat = flat.concat(flattenDescendants(page.descendants, p.path + "/"));
                }
                return flat;
            }, []);
        };

        return flattenDescendants(state.pages);
    },

    getPreviousPage(state, getters) {
        if (!state.page) return;

        const flatNav = getters.getFlatNav;
        const i = flatNav.findIndex((p) => p.id === state.page.id);

        if (i < 0) return; // Page wasn't found in nav
        if (i === 0) return; // Page is the first page and can't have a previous page

        return flatNav[i - 1];
    },

    getNextPage(state, getters) {
        if (!state.page) return;

        const flatNav = getters.getFlatNav;
        const i = flatNav.findIndex((p) => p.id === state.page.id);

        if (i < 0) return; // Page wasn't found in nav
        if (i === flatNav.length - 1) return; // Page is the last page and can't have a next page

        return flatNav[i + 1];
    },

    getLightMode(state) {
        return state.light_mode;
    },
};
