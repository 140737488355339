import Vue from 'vue';

Vue.prototype.$asset = (path) => {
	try {
		if (path.startsWith('images')) {
			return require('~/assets/images' + path.substring(6));
		} else if (path.startsWith('videos')) {
			return require('~/assets/videos' + path.substring(6));
		}
	} catch(error) {
		console.error(`Failed to load asset: ${path}`);
	}
}