import Vue from "vue";

Vue.directive("autoplay", {
    inserted(element, binding, vnode) {
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.01, 0.99],
        };
        const observer = new IntersectionObserver(([entry] = entries) => {
            if (entry.isIntersecting) {
                element.play().catch((e) => {
                    console.error(e);
                    const fallback = binding.value ? vnode.context.$refs[binding.value.ref] : null;
                    if (fallback) {
                        element.classList.add("autoplay--paused");
                        fallback.classList.add("autoplay--fallback");
                    }
                });
                observer.disconnect();
            }
        }, options);

        observer.observe(element);
    },
});
