import { uid } from "@/utils";

export const state = () => ({
    alerts: [],
});

export const mutations = {
    addAlert(state, alert) {
        state.alerts.push(alert);
    },
    removeAlert(state, alertId) {
        let i = state.alerts.findIndex((a) => (a.id = alertId));
        if (i > -1) state.alerts.splice(i, 1);
    },
};

export const actions = {
    createAlert(context, options) {
        return new Promise((resolve) => {
            const id = uid();
            const alert = {
                ...options,
                id: id,
                callback: async (result, closed) => {
                    resolve(result);
                    closed.then(() => {
                        context.commit("removeAlert", id);
                    });
                },
            };

            context.commit("addAlert", alert);
        });
    },
};

export const getters = {
    alerts(state) {
        return state.alerts;
    },
};
